import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "antd"
const Topbar = (props: { title: string }) => {

  const { title } = props;


  let tittle = '';
  if (title == "messagecenter") {
    tittle = "Message Center";
  } else if (title == "forYou") {
    tittle = "For You";
  } else if (title == "allInvestment") {
    tittle = "Investment Dashboard";
  } else if (title == "investments_dashboard") {
    tittle = "Investments";
  } else if (title == "dashboard_new") {
    tittle = "Home";
  } else if (title == "investment_choices") {
    tittle = "Investments";
  } else if (title == "fund-transfer") {
    tittle = "Investments";
  } else if (title == "investment-list-view") {
    tittle = "Investments";
  } else if (title == "Trending-and-Popular") {
    tittle = "Investments";
  } else if (title == "investment_choices2") {
    tittle = "Investments";
  }
  else if (title == "investment_choices") {
    tittle = "Investments";
  }
  else if (title == "Transfer-hsa-dollars") {
    tittle = "Investments";
  }
  else if (title == "transaction2") {
    tittle = "Investments";
  }
  else if (title == "transaction3") {
    tittle = "Investments";
  }
  else if (title == "transaction4") {
    tittle = "Investments";
  }

  const capitalize = (value: string) => value && value[0].toUpperCase() + value.slice(1);

  return (
    <>
      <div className="topNavBar" >
        <h1 className="headingTop headingTopWithButton">{tittle ? tittle : capitalize(title)} {title == 'expenses' && <Link to="/addExpenses"><Button type="default" className='theme-button'>Add Expense</Button></Link>}</h1>
        <div className="topNavRight">
          {/* <Link to="/" className="buttonMain">Add Expense</Link> */}
        </div>
      </div>
    </>
  );
};

export default Topbar;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Table, Select, Pagination, Row, Col, Card, Tabs, Button, Collapse, Input, Checkbox, Carousel, Slider, Radio } from "antd";
import { useActions, investmentAction } from "../../actions";
import { connect } from "react-redux";
import { notifyMe, Loader, LoaderStart } from "../../helper";
import { notify } from "superagent";
import FilterListViewComponent from "./FilterListViewComponent";
import FilterComponent from "./FilterComponent";
import {getFrequencyOfObjectArrayKey, appendTotalKey}  from './Utils';
const { TabPane } = Tabs;
const { Panel } = Collapse;
const GridListTables = ({ data, equityCategory }, props) => {
  const investmentcallAction = useActions(investmentAction);
  const [investmentQuestions, setinvestmentQuestions] = useState([]);
  const [investmentAmount, setinvestmentAmount] = useState([]);
  const [tickersymbols, settickersymbols] = useState([]);
  const [Conservative, setConservative] = useState([]);
  const [aggresive, setaggressive] = useState([]);
  const [moderateConservative, setmoderateConservative] = useState([]);
  const [moderate, setmoderate] = useState([]);
  const [moderateaggresive, setmoderateaggresive] = useState([]);
  const [isVisibility, setisvisibility] = useState(false);
  const [confirmmodal, setconfirmmodal] = useState(false);
  const [loader, setloader] = useState(false);
  const [portfolioticker, setportfolioticker] = useState([]);
  const [investmentId, setInvestmentId] = useState('');
  const [recommendedCategory, setrecommendedCategory] = useState('2');
  const [formcompleted, setformcompleted] = useState('inprogress');
  const [answers, setAllanswers] = useState({
    overviewques1: 20,
    overviewques2: 20,
    typeselected: 'managed',
    amount: '0.00',
  });

  const [value4, setValue4] = useState('CardView');
  const [gridSlider, setGridSlider] = useState(true);
  const [cardViewShow, setCardViewShow] = useState(true);
  const [listViewShow, setListViewShow] = useState(false)
  const onChange4 = e => {
    setValue4(e.target.value)
    if (e.target.value === 'ListView') {
      setGridSlider(false);
      setCardViewShow(false);
      setListViewShow(true);
    }
    if (e.target.value === 'CardView') {
      setGridSlider(true);
      setCardViewShow(true);
      setListViewShow(false);
    }
  };
  const plainOptions = ['CardView', 'ListView',];
  const cardViewListView = [
    { label: 'Card View', value: 'CardView' },
    { label: 'List View', value: 'ListView' },
  ];
  const { value, onChangeFunction } = props;
  const [currentValue, changeValue] = useState(value);
  const [gridLayout, setGridLayout] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [filterData, setFilterData] = useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [cardLength, setCardLength] = useState(null)
  const onChange = (newValue) => {
    changeValue(newValue);
    if (newValue > 40) {
      setGridLayout(false);
    } else {
      setGridLayout(true);
    }
  }

  const filterSelect = (e, index) => {
    if (e == 'Show All') {
      setFilterValue('');
    } else {
      setFilterValue(e);
    }
    setFilterData(true);
    setActiveIndex(index);

  }

  let setFillValue = ''
  const filterSelect2 = (e, index) => {
    if (e == 'Show All') {
      setFillValue = ''
    } else {
      setFillValue = e;
    }
    // setFilterData(true);
    // setActiveIndex(index);

  }

  useEffect(() => {
    // Update the document title using the browser API
    // filterSelect();
  });
  // filterSelect();

  console.log(data)

  const filtered = !filterValue
    ? data
    : data.filter((person) =>
    person.asset_class_morningstar_category.toLowerCase().includes(filterValue.toLowerCase())
  );

  console.log(filterValue)


const resultEql =  getFrequencyOfObjectArrayKey(data, 'asset_class_morningstar_category')
appendTotalKey(resultEql, 'Show All')

  return (
    <>
      <Card className="investMentPageSteps bottomFilterMain mt-0">
        <div className="d-flex bottomFilterMainHeader">
          <div className="PrimaryTwoSwitch">
            <Radio.Group
              options={cardViewListView}
              onChange={onChange4}
              value={value4}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
        <div className="ButtonFilterHeader d-flex">
          <Col className="px0" span={16} >
            <div className="multiBtnBar d-flex">
                  {/* <a  className="out-Grey-btn btn active" onClick={()=>filterSelect('')}>Show All</a> */}
                  {cardViewShow ?<>  {
                    equityCategory && equityCategory.length > 0 && equityCategory?.map((val, index) =>
                      <a key={index} onClick={()=>filterSelect(val, index)} className={activeIndex === index ? "out-Grey-btn btn active" : "out-Grey-btn btn"}>
                        {/* <span className="addCheck"></span> */}

                        {console.log('values', filtered.length && data.asset_class_morningstar_category === filtered.asset_class_morningstar_category ? filtered.length : '')}
                        {val} &nbsp; <span>({resultEql?.[val] || ''})</span> </a>
                      )
                  }</> : '' }
                  {/* <a  className="out-Grey-btn btn">Price (0 - 100+)</a>  */}
            </div>
          </Col>
          <Col className="px0" span={7}>
            {gridSlider ?
              <>
                <div className="rangeFilter ml-auto">
                  <div className="RangePrefix"></div>
                  <Slider tooltip={{
            open: false,
          }} value={currentValue} onChange={onChange} className="d-flex" defaultValue={20} />
                  <div className="RangePostfix"></div>
                </div></>
              : ''}
          </Col>
        </div>
        {cardViewShow ?
          <FilterComponent slider={currentValue} data={filterData ? filtered : data} filterValue={filterValue} />
          : ''}
        {listViewShow ?
          <FilterListViewComponent data={data} />
          : ''}
      </Card>
    </>
  );
};
function mapStateToProps(state) {
  return {
    investmentQuestions: state.investmentsReducer,
  };
}
export default connect(mapStateToProps)(GridListTables);

import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const myProfile = createReducer({}, {
	[ActionType.MY_PROFILE](state: any, action: Action<any>) {
		return action.payload;
	},	
});

export const myState = createReducer({}, {
	[ActionType.PARTICIPANT_STATES](state: any, action: Action<any>) {
		return action.payload;
	},	
});

export const updatePassword = createReducer({}, {
	[ActionType.UPDATE_PASSWORD](state: any, action: Action<any>) {
		return action.payload;
	},	
});

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// import "antd/dist/antd.css";
import { Drawer, Card, Image, Space, Tag, Tabs, Divider } from 'antd';
import { InfoCircleOutlined, UserOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import SelfdirectedOverview from "./SelfDirectedOverview";
import ishares from '../../assets/images/logo/ishare-logo.png'
const { TabPane } = Tabs;

const FilterListViewComponent = ({ data }, props) => {
  const [value4, setValue4] = useState("CardView");

  const onChange4 = (e) => {
    console.log("radio4 checked", e.target.value);
    setValue4(e.target.value);
  };
  const plainOptions = ["CardView", "ListView"];
  const cardViewListView = [
    { label: "Card View", value: "CardView" },
    { label: "List View", value: "ListView" },
  ];
  const { value, onChangeFunction } = props;
  const [currentValue, changeValue] = useState(value);
  const [gridLayout, setGridLayout] = useState(true);

  const onChange = (newValue) => {
    changeValue(newValue);
    if (newValue > 40) {
      setGridLayout(false);
    } else {
      setGridLayout(true);
    }
  };
  const [visible, setVisible] = useState(false);
  const [modalValue, setModelValue] = useState('')
  const showDrawer = (val) => {
    console.log('val', val);
    setModelValue(val)
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <div className="ComapnyCardWrappersMain listview">
        {data?.map((val, i) => (
          <div className="CompanyListViewItm" key={i}>
            <div className="companyCardHeader">
              <div className="brandinfo">
                <div className="brandLogo">
                  <img src={val && val.image} />
                </div>
                <div className="brandName">

                  <a href={val && val.research} target="_blank">
                    <h4>{val && val.ticker}</h4>
                  </a>
                  <div className="CopanyTitle">
                    <h5>{val &&  val.name}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="DataSetings">
              <div className="settingButtons dataItm">
                <span>Quantity</span>
                <h6>{val ? val.OpenQty : "-"}</h6>
              </div>
              <div className="price dataItm">
                <span>Price</span>
                <h6>{val ? "$" + val.cost : "-"}</h6>
              </div>
              <div className="today dataItm">
                <span>Value</span>
                {val && <h6 className={parseFloat(val.unrealizedPL) < 0 ? "--value-down" : "--value-Up"}>

                  {val && val.value ? (
                    <>
                      {parseFloat(val.unrealizedPL) < 0 ? <ArrowDownOutlined style={{ color: '#DA100B' }} /> : <ArrowUpOutlined style={{ color: '#00e096' }} />}
                      {"$" + val.value}
                    </>
                  ) : (
                    "-"
                  )}
                </h6>}
              </div>
              <div className="marketCap dataItm">
                <span>Market Value</span>
                <h6> {val && val.marketvalue ? "$" + val.marketvalue : "-"}</h6>
              </div>
              <div className="marketCap dataItm">
                <span>Market Price</span>
                <h6> {val && val.mktprice ? "$" + val.mktprice : "-"}</h6>
              </div>
              <div className="marketCap dataItm">
                <span>Unrealized PL</span>
                <h6 > {val && val.unrealizedPL ? "$" + val.unrealizedPL : "-"}</h6>
              </div>
              <div className="dataItm" style={{ maxWidth: '10%' }} onClick={() => showDrawer(val)}>
                <a className="viewBtn"></a>
              </div>




            </div>
          </div>
        ))}
        <Drawer width='670'
          placement="right"
          onClose={onClose}
          open={visible}
          closable={false}
        >

          <Card className="self-directed-overview">
            <Space direction="horizontal" className="space-between">
              <Image src={modalValue?.image} alt="" style={{ maxWidth: '185px', width: '100%', height: '90px' }} preview={false} />
              <h2>{modalValue?.ticker}</h2>
            </Space>
          </Card>
          <Divider />
          <h3 className="overlay-text">{modalValue?.name}</h3>
          <Space direction="horizontal" className="space-between">
            <Card className="ishare-card1">
              <span>Today's Change</span>
              <h4 className="mb-0">${modalValue?.unrealizedPL}
                {/* <Tag className="value-change">+2.5%</Tag> */}
                <Tag className="value-reflect1">{modalValue?.unrealizedPL < 0 ?  <ArrowDownOutlined /> :<ArrowUpOutlined />}</Tag>
              </h4>
            </Card>

            <Card className="ishare-card2">
              <span> Market Value</span>
              <h4 className="mb-0">${modalValue?.marketvalue}
                {/* <Tag className="value-change">+2.5%</Tag> */}
                <Tag className="value-reflect2">{modalValue?.unrealizedPL < 0 ?  <ArrowDownOutlined /> :<ArrowUpOutlined />}</Tag>
              </h4>
            </Card>
          </Space>
          {/*** TODO :- UNCOMMENT AFTER DEMO*/}
          {/* <div className="self-directed-overview-tabs">
            <Tabs defaultActiveKey="2">
              <TabPane tab="Buy" key="1">

              </TabPane>
              <TabPane tab="Review" key="2">

              </TabPane>
              <TabPane tab="Complete" key="3">

              </TabPane>
            </Tabs>
          </div> */}
        </Drawer>
      </div>
    </>
  );
};
export default withRouter(FilterListViewComponent);

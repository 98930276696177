import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { CaretRightOutlined } from '@ant-design/icons';
import ProfileImgLeft from '../../assets/images/ProfileImgLeft.png';
import { useActions, authAction, myProfileAction } from "../../actions";
interface FullProps {
    children: any,
  }
const Full = (props: FullProps) => {
  const { children } = props;
  const [isShowSetting, setIsShowSetting] = useState<boolean>(false)
  const authActions = useActions(authAction);
  const myProfileauth = useSelector((state: RootState) => state.auth);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowSetting(isShowSetting)
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const logOut = async () => {
    window.localStorage.clear();
    await authActions.SignOut()
  }
  console.log('hehre')
console.log(myProfileauth)
  return (
    <div className="login-page full-page-scroll">
      <div className="top-green-header">
        <div className="login-logo">
              <img src="/assets/images/logo/LogoSOWhite.svg" alt={"Logo"} />
          </div>
          {
            Object.keys(myProfileauth).length !== 0
            ?
            <div className="letSideProfileLogout" ref={wrapperRef} onClick={() => setIsShowSetting(!isShowSetting)}>
                {myProfileauth?.user ? (<div className="profileIcon" style={{ backgroundImage: 'url(' + myProfileauth?.user?.picture + ')' }} ></div>) : (<div className="profileIcon" style={{ backgroundImage: 'url(' + ProfileImgLeft + ')' }} ></div>)}

                <div className="nameIDLeft">
                    <div className="leftName">{myProfileauth?.user ? myProfileauth?.user?.name : 'John Doe'}<br /> </div>
                </div>
                <div className="actionRight" >
                    <CaretRightOutlined style={{ fontSize: '14px', color: '#FFFFFF' }} />
                    {/* <Link to="" className="linkIcon"></Link> */}
                </div>
                {
                    isShowSetting &&
                    <div className="sidebarMenuDrop">
                    <Link to="/signIn" className="logOut" onClick={logOut} >
                        <span className="menuIconLeftSide icon6"></span> Logout</Link>
                    </div >
                }
            </div>
            :
            null
          }
          
      </div>
      <div className="container-fluid">
        <Row className="content-center">
          <Col  xs={24} sm={24} md={24} lg={18} xl={18} >
           <div className="auth-container">
            {children}
           </div>
        </Col>
        </Row>
      </div>
    </div>
  );
};

export default Full;

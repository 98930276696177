import moment from 'moment';

export const validateEmail = (value: any) => {
  let errors;
  value = value.trim();
  if (!value) {
    errors = "Required!";
  } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
    errors = "Invalid email address!";
  }

  return errors;
};

export const isReqtwochar = (value: any) =>  {
  let errors;
  if (!value) {
    errors = "Required!";
  } else if (value.length <= 2) {
    errors = "Must have atleast 3 alphabets.";
  }
  return errors;
}

export const istwocharcheck = (value: any) =>  {
  let errors;
  if (value && value.length <= 2) {
    errors = "Must have atleast 3 alphabets.";
  }
  return errors;
}

export const isRequired = (value: any) => (!value ? "Required!" : "");

export const hereByAccept = (value: any) => (!value ? "You must acknowledge and accept SavingsOak Terms of Service and Privacy Policy." : "");
export const hereByAcceptDw = (value: any) => (!value ? "You must acknowledge and accept  DriveWealth's (SavingsOak's investment partner) Privacy Policy, W9, and Disclosures." : "");


export const validDob = (value: any) => {
  let errors;

  if (!value) {
    errors = "Required!";
  }


  if (!moment(value, "MM/DD/YYYY", true).isValid()) {
    errors = "Required";
  } else if (moment(new Date()).diff(moment(new Date(value)), 'years') > 100 ) {
    errors = "Members above 100 years of age are not allowed!";
  }
  return errors;
}

export const validSsn = (value: any) => {
  let errors;
  const ssnNumberRegex = /(?=.*[0-9])/;

  if (!value) {
    errors = "Required!";
  } else if (value.length < 4 || value.length >= 5 || !ssnNumberRegex.test(value)) {
    errors = "SSN Number must be 4 digit long.";
  }
  return errors;
}

export const validPassword = (value: any) => {
  let errors;
  const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

  if (!value) {
    errors = "Required!";
  } else if (!passwordRegex.test(value)) {
    errors = "Password must have at least 8 characters and should contain one uppercase, one lowercase, one number, and one symbol (@$!%*?&).";
  }
  return errors;
}

export const currentPassword = (value: any) => {
  let errors;
  const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

  if (!value) {
    errors = "Required!";
  }

  return errors;
}

export const  validNewPassword = (value: any) => {
  let errors;
  const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

  if (!value) {
    errors = "Required!";
  } else if (!passwordRegex.test(value)) {
    errors = "Password must have at least 8 characters and should contain one uppercase, one lowercase, one number, and one symbol (@$!%*?&).";
  }
  return errors;
}

export const confirmPassword = (pass: any, value: any) => {
  let errors;

  if (!value) {
    errors = "Required!";
  } else if (pass && value) {
    if (pass !== value) {
      errors = "New Password does not match with Confirm Password.";
    }
  }
  return errors;
}

export const validatessnconfirmssn = (pass: any, value: any) => {
  let errors;

  if (!value) {
    errors = "Required!";
  } else if (pass && value) {
    if (pass !== value) {
      errors = "Confirm ssn does not match with ssn.";
    }
  }
  return errors;
}

import { ActionType } from "../model";
import { notifyMe } from "../helper";



export const BeneficiariesList = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_beneficiarys', data, getState().auth.access_token);
		return dispatch({
			type: ActionType.LIST_BENEFICIARIES,
			payload: resp.data
		});
	} catch (err) {
		console.error('Ben Error: ', err);
	}
};


export const AddBeneficiary = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('add_beneficiary', data, '', getState().auth.access_token);
		return dispatch({
			type: ActionType.ADD_BENEFICIARY,
			payload: resp
		});
	} catch (err) {
		console.error('ADD BENEFICIARY Error: ', err);
	}
};

export const EditBeneficiary = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('edit_beneficiary', data, '', getState().auth.access_token);
		return dispatch({
			type: ActionType.EDIT_BENEFICIARY,
			payload: resp
		});
	} catch (err) {
		console.error('Edit BENEFICIARY Error: ', err);
	}
};

export const DeleteBeneficiary = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('delete_beneficiary', data, '', getState().auth.access_token);
		return dispatch({
			type: ActionType.DEL_BENEFICIARY,
			payload: resp
		});
	} catch (err) {
		console.error('Delete BENEFICIARY Error: ', err);
	}
};
import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout'
import { Minimal as MinimalLayout } from '../layouts';
import { Sponsor as SponserLayout } from '../layouts';


const PublicRoutes = () => {
    return (
        <Switch>

            {/* Start new route for Sponsor */}
            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/sponsorVerification')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/verification/:token/:email"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/SignIn')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/signIn/:token?/:email?"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ActivateAccount')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/activateaccount"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ForgotPassword')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/forgotPassword"
            />

            <RouteWithLayout
                component={lazy(() => (import('../views/sponsor/ChangePassword')))}
                exact
                layout={MinimalLayout}
                path="/sponsor/changePassword/:token/:email"
            />
            <Redirect from='/' to='/sponsor/signIn' />
        </Switch>
    )
};

export default PublicRoutes;

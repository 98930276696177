import { Action, SponsorActionType, ActionType } from "../../../model";
import createReducer from "../createReducer";

export const SponsorStates = createReducer({}, {
	[SponsorActionType.SPONSOR_STATES](state, action) {
		return action.payload;
	}	
});
export const checkLinkedBankAccounts = createReducer({}, {
	[ActionType.LINKED_BANK_ACCOUNTS](state, action) {
		return action.payload;
	}	
});
export const checkAccessToken = createReducer({}, {
	[ActionType.CHECK_ACCESS_TOKEN](state, action) {
		return action.payload;
	}	
});
export const getCashStatment = createReducer({}, {
	[ActionType.CASH_STATEMENT](state, action) {
		return action.payload;
	}	
});
export const getDwTransactions = createReducer({}, {
	[ActionType.GET_DW_TRANSACTIONS](state, action) {
		return action.payload;
	}	
});
export const getInvestmentStatment = createReducer({}, {
	[ActionType.INVESTMENT_STATEMENT](state, action) {
		return action.payload;
	}	
});
export const getInvestmentFileKey = createReducer({}, {
	[ActionType.INVESTMENT_FILE_KEY](state, action) {
		return action.payload;
	}	
});

export const participantNotificationStatus = createReducer({}, {
	[ActionType.PARTICIPANT_NOTIFICATION_STATUS](state, action) {
		return action.payload;
	}	
});

export const sendNotificationEmail = createReducer({}, {
	[ActionType.SEND_NOTIFICATION_EMAIL](state, action) {
		return action.payload;
	}	
});










import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Select,
  Pagination,
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Collapse,
  Input,
  Checkbox,
  Carousel,
  Radio,
  Breadcrumb,
  Steps,
  message,
} from "antd";



const MoveMoney = () => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
    console.log(current + "steps count")
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <>
      
    </>
  );
};

export default MoveMoney;

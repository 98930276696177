import { SponsorActionType } from "../../model";
import { notifyMe } from "../../helper";

export const getDashboardData = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_dashboard_data', {}, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_GET_DASHBOARD, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getIndivsualData = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_individual_by_id', data, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_INDIVISUAL_DATA, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getSponsorNotification = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_notifications_sponsor', data, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_NOTIFICATIONS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const sendReminder = (data) => async (dispatch, getState, api) => {
	try {
        let resp = await api.post('send_reminder', data, '', getState().sponsorauth.access_token);
        if (resp.code == '200') {
            return {'status': '200', 'message': 'Reminder was send to users'};
        } else {
            return {'status': '200', 'message': 'Error in sending reminders, Please contact admin'};
        }
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};


export const getsponsoremailprefrence = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_emailpreferences', data, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_GET_EMAIL_PREFRENCES, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const updatesponsoremailprefrences = (data) => async (dispatch, getState, api) => {
	try {
        let resp = await api.post('update_sponsor_emailpreferences', data, '', getState().sponsorauth.access_token);
        if (resp.code == '200') {
            return {'status': '200', 'message': 'Email prefrence status updated'};
        } else {
            return {'status': '200', 'message': 'Error in updating, Please contact admin'};
        }
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getSponsorNotificationDetail = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_notifications_sponsor_for_messages_screen', data, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_NOTIFICATION_DETAIL, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const readSponsorNotification = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('update_notification_status_sponsor', data, '', getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_NOTIFICATION_READ, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};
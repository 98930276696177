import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const transactions = createReducer({}, {
	[ActionType.LIST_TRANSACTIONS](state: any, action: Action<any>) {
		return action.payload;
	}
});

export const getlaterExpense = createReducer({}, {
	[ActionType.GET_LATER_EXPENSE](state: any, action: Action<any>) {
		return action.payload
	},
});

export const expensePaginate = createReducer({}, {
	[ActionType.GET_EXPENSE_FILTERS](state: any, action: Action<any>) {
		return action.payload
	},
});

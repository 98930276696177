import React, {useEffect} from 'react';
import { connect } from "react-redux";
import { Router } from 'react-router-dom';
import { history } from "./redux/store";
import { useActions, authAction, authSponsorAction } from "./actions";
import Routes from './routes';
import SponsorRoutes from './sponsorroutes';
import "antd/dist/antd.css";
import './assets/scss/index.scss';
import './assets/scss/responsive.scss';
import SessionTimeout from './SessionTimeout';

function App(props) {

  useEffect(() => {
    if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "production") {
        console.log = () => {};
    }
  }, []);

  let isAuthenticated = '';
  let typeofaction, usertype;
  if(window.location.pathname.includes('sponsor')) {
    if(props.sponsorauth.hasOwnProperty("access_token")) {
      if(new Date(props?.sponsorauth.token_iat).getTime() < new Date(props?.sponsorauth.token_exp).getTime()) {
        isAuthenticated = !!props?.sponsorauth.access_token;
      } else {
        window.localStorage.clear();
        isAuthenticated = '';
      }
    }
    usertype = 'sponsor';
    typeofaction = authSponsorAction;
  } else {
    if(props.auth.hasOwnProperty("access_token")) {
      if(new Date(props?.auth.token_iat).getTime() < new Date(props?.auth.token_exp).getTime()) {
        isAuthenticated = !!props?.auth.access_token;
      } else {
        window.localStorage.clear();
        isAuthenticated = '';
      }
      usertype = 'indivisual';
    }
    typeofaction = authAction;
  }

  const authActions = useActions(typeofaction);

  const handleClick = async () => {
    window.localStorage.clear();
    await authActions.SignOut();
  }

  
  if(window.location.pathname !== '/addExpenses'){
    window.localStorage.removeItem("expense_data");
    window.localStorage.removeItem("reciept_image");
    window.localStorage.removeItem("reciept_name");
  }

  history.listen((location, action) => {
    if(window.location.pathname !== '/addExpenses'){
      window.localStorage.removeItem("expense_data");
      window.localStorage.removeItem("reciept_image");
      window.localStorage.removeItem("reciept_name");
    }
  })


  return (
    <>
      {/* <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} /> */}
      {usertype == 'sponsor' ? (
        <Router history={history}>
          <SponsorRoutes isAuthenticated={isAuthenticated} />
        </Router>
      ) : ( 
        <Router history={history}>
          <Routes isAuthenticated={isAuthenticated} />
        </Router>
      )}
      
    </>  
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    sponsorauth: state.sponsorauth
  };
}

export default connect(mapStateToProps)(App);

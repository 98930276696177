import React, { Component } from "react";
import Chart from "react-apexcharts";

class FundDistributionChart extends Component {
    constructor(props) {
        super(props);

        let $primary ="#2CE2AC",
        $success = "#0F9C8E"
        

    let themeColors = [$primary, $success,]

        this.state = {
        
          series: [60],
          options: {
            chart: {
              height: 300,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                 hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                  }
                },
                track: {
                  background: '#fff',
                  strokeWidth: '67%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    // offsetY: -10,
                    show: true,
                    color: '#28C3B4',
                    fontSize: '17px',
                    
                  },
                  value: {
                    formatter: function(val) {
                      return parseInt(val);
                    },
                    color: '#111',
                    fontSize: '36px',
                    show: false,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                // shade: 'dark',
                type: 'horizontal',
                // shadeIntensity: 0.5,
                // gradientToColors: ['#ABE5A1'],
                gradientToColors: themeColors,
                
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },

            labels: ['Total Allocated 60%'],
          },
        
        
        };
      }

  render() {
    return (
  
   
          <div className="mixed-chart">
            <Chart className="fundDistriChart"
              options={this.state.options}
              series={this.state.series}
              type="radialBar"
              width="270"
            />
          </div>
 
 
    );
  }
}

export default FundDistributionChart;
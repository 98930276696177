import React, { useState, useEffect } from 'react'
import {
  Table,
  Select,
  Pagination,
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Collapse,
  Input,
  Checkbox,
  Carousel,
  Radio,
  Breadcrumb,
  Steps,
  message,
  Spin,
  InputNumber,
} from "antd";
import ApexCharts from 'apexcharts';

import Moment from 'moment';
import momentTz from 'moment-timezone';

import MoveMoney from '../../../views/Investments/FundTransfer/MoveMoney';
import { useActions, investmentAction } from "../../../actions";
import GridListTables from '../../../views/ViewComponent/GridListTables';




const { TabPane } = Tabs;



const PendingTrade = (props) => {
  const [transArr, setTransArr] = useState([]);
  const [rows, setRows] = useState(10);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [page, setPage] = useState(0);
  const investmentcallAction = useActions(investmentAction);
  const [dashboardData, setdashboardData] = useState([]);
  const [investmentequity, setInvestmentequity] = useState([]);
  const [typeofchart, settypeofchart] = useState('week');
  const [asofdate, setasofdate] = useState('');
  const [activeTab, setactiveTab] = useState('1');
  const [dwAccountNumber, setDwAccountNumber] = useState();
  const [showstate, setshowstate] = useState('fund');
  const [noDataFound, setNoDataFound] = useState('')
  const [totalPrice, setTotalPrice] = useState('0');
  const [isloading, setIsloading] = useState(false);
  const [equityCategory, setEquaityCategory] = useState([]);




  useEffect(() => {
    _fetchDashboardData();
  }, []);

  const _fetchDashboardData = async () => {
    setIsloading(true)
    // let dashboard = await investmentcallAction.getinvestmentsdashboard();
    let dashboard = await investmentcallAction.getinvestmentequity();
    setdashboardData(dashboard.payload);
    if (dashboard.payload.order_complete == 0) {
      let tz = momentTz.tz.guess();
      setasofdate('As of, ' + Moment(dashboard.payload.lastUpdated).format('llll') + ' (' + momentTz().tz(tz).format('z') + ')')
      if (dashboard.payload.equityPositions.length > 0) {
        setInvestmentequity(getarray(dashboard.payload.equityPositions))
        setTotalPrice(dashboard.payload.total_price)
      setEquaityCategory(dashboard.payload.asset_class_morningstar_category)

        setIsloading(false)
      }
    } else {
      setIsloading(true)
      setNoDataFound('There are no pending orders at this time for your account.')
      setIsloading(false)
    }

    setDwAccountNumber('Investment Account Number: ' + dashboard.payload.dw_account_number)
    callInterval();
  }
  const callInterval = () => {
    window.setInterval(function () {
      _fetchDashboardData();
    }, 300000);
  }

  const getarray = (arrval) => {
    let arr = [];
    arrval.map((val, i) => {
      arr.push({
        key: i,
        name: val.name ? val.name : '',
        ticker: val.symbol ? val.symbol : '',
        research: val.url ? val.url : '',
        image: val.image ? val.image : '',
        OpenQty: val.openQty ? val.openQty : '',
        cost: val.costBasis ? val.costBasis : '',
        mktprice: val.mktPrice ? val.mktPrice : '',
        marketvalue: val.marketValue ? val.marketValue : '',
        marketprice: val.mktPrice ? val.mktPrice : '',
        unrealizedPL: val.unrealizedPL ? val.unrealizedPL : '',
        mktPrice: val.mktPrice ? val.mktPrice : '',
        value:val.close ? val.close : '',
        asset_class_morningstar_category: val.asset_class_morningstar_category ? val.asset_class_morningstar_category : '',
      });
    });
    return arr;
  }




  return (
    <>

      <div className=" ScrollViewMain scrollDashboard moveMoneyWrapper investmentPage">
        <Card className="TransactionMain">
          <Breadcrumb className="FundTrabsferBreadcrumb" separator=">">
            <Breadcrumb.Item>Transactions</Breadcrumb.Item>
            <Breadcrumb.Item>Pending Orders</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="row">
            <Col span={24} className="col-md-8">
              <div className="investBannerText pt0">
                <div>
                  <h2 className="investSubHead">
                    Pending Orders
                  </h2>
                  <p>
                    Trades listed below will be submitted for execution upon market open unless cancelled while pending.
                    Share amounts listed below are an estimate until trade is executed at actual market share shares.

                  </p>
                </div>
              </div>
            </Col>

          </Row>



          <Row>
            <Col span={24}>
              {isloading ? <div className="spinnerCont"><Spin tip="Loading..." /></div> :

                <div className="ComapnyCardWrappersMain ">
                  {
                    noDataFound == '' ?
                      <GridListTables data={investmentequity} equityCategory={equityCategory}  />
                      // investmentequity.map((val) =>
                      //   <div className="CompanyListViewItm">
                      //     <div className="companyCardHeader">
                      //       <div className="brandinfo">
                      //         <div className="brandLogo">
                      //           <img src={val.image} />
                      //         </div>
                      //         <div className="brandName">
                      //           <h4>{val.ticker}</h4>
                      //           <div className="CopanyTitle">
                      //             <h5>{val.name}</h5>
                      //           </div>
                      //         </div>
                      //       </div>
                      //     </div>
                      //     <div className="DataSetings">

                      //       <div className="today dataItm">
                      //         <span>Open Quantity</span>
                      //         <h6>{val.OpenQty ? val.OpenQty : 'processing...'}</h6>
                      //       </div>
                      //       <div className="marketCap dataItm">
                      //         <span>Cost</span>
                      //         <h6>{val.cost ? '$' + val.cost : 'processing...'}</h6>
                      //       </div>
                      //       <div className="marketCap dataItm">
                      //         <span>Market Value</span>
                      //         <h6>{val.marketvalue ? '$' + val.marketvalue : 'processing...'}</h6>
                      //       </div>
                      //       <div className="marketCap dataItm">
                      //         {console.log('val.mktPrice', val)}
                      //         <span>Market Price</span>
                      //         <h6>{val.marketprice ? '$' + val.marketprice : 'processing...'}</h6>
                      //       </div>
                      //       <div className="marketCap dataItm">
                      //         <span>Unrealized PL</span>
                      //         <h6>{val.unrealizedPL ? '$' + val.unrealizedPL : 'processing...'}</h6>
                      //       </div>
                      //       <div className="price dataItm">
                      //         <span>Research</span>
                      //         <h6>
                      //           <a href={val.research} target="_blank">LINK</a>
                      //         </h6>
                      //       </div>
                      //       <div className="settingButtons dataItm">
                      //         <a className="PlusBtn d-none"></a>
                      //         <a className="viewBtn ml-0"></a>
                      //       </div>

                      //     </div>
                      //   </div>
                      // )
                      : <p>{noDataFound}</p>
                  }
                </div>

              }
            </Col>
          </Row>
          <div className='totalDate'>
            <p className="totalAllocate">{totalPrice != '0' ? <>TOTAL :   ${totalPrice}</> : ''}</p>

            <p> {asofdate != '' ? <><strong>* Data</strong> {asofdate}</> : ''}</p>
          </div>

        </Card>
      </div>




    </>
  )
}

export default PendingTrade;

import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { Sidebar, Topbar } from './components';


const Sponsor = (props) => {
  const { children, history } = props;

  let arr = history.location.pathname.split('/');

  let title = (arr[2] === 'dashboard') ? 'home' : arr[2];

  if(title == 'foryou') {
    title = 'For You';
  } else if (title == 'accounts') {
    title = 'Profile';
  } else if (title == 'participantdetails') {
    title = 'Participant Details';
  }

  const settingRoutes = ['/sponsor/dashboard', '/sponsor/participants'];

  return (
    <>
      <div className="mainContainer">
        <Sidebar />
        <div className="mainContent">
            <Topbar
              title={title}
            />
          <>
            {children} 
          </>
        </div>
      </div>
    </>
  );
};

export default withRouter(Sponsor);

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons';
import logo from '../../../../assets/images/LogoDarkNew.svg';
import ProfileImgLeft from '../../../../assets/images/ProfileImgLeft.png';
import { useActions, authSponsorAction } from "../../../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import logoCollaplse from '../../../../assets/images/LogoIcon.svg';


const Sidebar = (props) => {
  const [isShowSetting, setIsShowSetting] = useState(false);
  const authActions = useActions(authSponsorAction);

  const logOut = async () => {
    window.localStorage.clear();
    await authActions.SignOut()
  }

  function useOutsideAlerter(ref) {
      useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                setIsShowSetting(isShowSetting)
              }
          }

          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              // Unbind the event listener on clean up
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [SidebarCollapse, sidebarShowHide] = React.useState(false);


  const PlanSelectClick = (e) => {
    if(!SidebarCollapse) sidebarShowHide(true);
    else sidebarShowHide(false);
  }

  return (

       <>
        <div className={SidebarCollapse ? "leftSidebar Collapsed" : "leftSidebar"}>

        <nav>
          <ul className="mainMenu">

          <li className="logo"  ><div> {SidebarCollapse ? <img src="/SO_favicon_green.png" alt=" Logo" /> : <img src="/assets/images/logo/SO_Logo_Green.png" alt=" Logo" /> }  </div><a href="#" className="menuHideShow" onClick={PlanSelectClick} > {SidebarCollapse ? <span className="menuIconCollapse"></span> :<span className="menuIconCollapse menuRight"></span>} </a> </li>
            <li><NavLink to="/sponsor/dashboard" activeClassName="active"><span className="iconLetBar icon1"></span> Home</NavLink></li>
            <li> <NavLink to="/sponsor/participants" activeClassName="active"><span className="iconLetBar sicon2"></span> Participants</NavLink></li>
            <li> <NavLink to="/sponsor/reports" activeClassName="active"><span className="iconLetBar sicon3"></span> Reports</NavLink></li>
            <li> <NavLink to="/sponsor/documents" activeClassName="active"><span className="iconLetBar icon5"></span> Documents</NavLink></li>
            <li> <NavLink to="/sponsor/foryou" activeClassName="active"><span className="iconLetBar icon6"></span> For You</NavLink></li>
            <li><NavLink to="/sponsor/messages" activeClassName="active"><span className="iconLetBar icon4">
            </span> Messages {props?.sponsorauth?.user?.unread_notifications_count > 0 && <span className="countLeftSide">{props?.sponsorauth?.user?.unread_notifications_count }</span>}</NavLink>
            </li>
            <li><NavLink to="/sponsor/settings" activeClassName="active"><span className="iconLetBar sicon4"> </span> Settings </NavLink>
            </li>
            {/* <li>
              <NavLink to="/sponsor/admin" activeClassName="active"><span className="iconLetBar sicon4"> </span> Admin Controls </NavLink>
            </li> */}
          </ul>
        </nav>
        <div className="letSideProfile" ref={wrapperRef} onClick={() => setIsShowSetting(!isShowSetting)}>
          {props?.sponsorauth.user ? (<div className="profileIcon" style={{ backgroundImage: 'url(' + props?.sponsorauth.user.picture + ')' }} ></div>) :( <div className="profileIcon" style={{ backgroundImage: 'url(' + ProfileImgLeft + ')' }} ></div>) }

          <div className="nameIDLeft">
           <div className="leftName">{props?.sponsorauth.user ? props?.sponsorauth.user.name : '' }<br /> </div>
          </div>
          <div className="actionRight" >
            <CaretRightOutlined style={{ fontSize: '14px', color: '#FFFFFF' }} />
            {/* <Link to="" className="linkIcon"></Link> */}
          </div>
          {
            isShowSetting &&
            <div className="sidebarMenuDrop">
              {/* <Link to="/settings/profile" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon1" ></span> Profile</Link>
              <Link to="/settings/dependents" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon2" ></span> Dependents</Link>
              <Link to="/settings/banking" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon3" ></span> Banking</Link>
              <Link to="/settings/beneficiaries" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon3" ></span> Beneficiary</Link>
              <Link to="/settings/preferences" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon5" ></span> Preferences</Link> */}
                 <Link to="/sponsor/accounts" className="logOut" >
                <span className="menuIconLeftSide icon7"></span> Profile</Link>
                <Link to="/sponsor/preferences" className="logOut" >
                <span className="menuIconLeftSide icon5"></span> Preferences</Link>
              <Link  className="logOut" onClick={logOut} >
                <span className="menuIconLeftSide icon6"></span> Logout</Link>
            </div >
          }
        </div>
      </div>
    </>

  );
};

function mapStateToProps(state) {
  return {
    sponsorauth: state.sponsorauth,
    ParticipantReducer: state.ParticipantReducer

  };
}

export default connect(mapStateToProps) (Sidebar);

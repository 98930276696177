import { SponsorActionType } from "../../../model";
import createReducer from "../createReducer";

export const sponsorauth = createReducer({}, {
	[SponsorActionType.SPONSOR_SIGNIN](state, action) {
		return action.payload;
	},
	[SponsorActionType.SPONSOR_USER_INFO](state, action) {
		return {
			...state,
			user: action.payload
		}
	},
	[SponsorActionType.SPONSOR_SIGNOUT](state, action) {
		return action.payload;
	},
});
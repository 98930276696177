import React, {useState} from 'react';
import ManageDashboard from '../../common/slider/Managedashboard';
import { Row, Col, Card, Table, Select, Spin, Button } from "antd";
import InvestmentDashboardDonutChart from "../../investment/investmentdashboard/Charts/InvestmentDashboardDonutChart";
import InvestmentDashboardLineChart from "../../investment/investmentdashboard/Charts/InvestmentDashboardLineChart";
import GridListTables from '../../../views/ViewComponent/GridListTables';
import InvestModal from "../../../components/investment/investModal";
import AdvCrs from '../../../components/investment/advCrs';

const InvestmentOverview = ({ asofdate, dashboardData, typeofchart, settypeofchart, investmentequity, dwAccountNumber, equityCategory, Investmentdata, contributionSummary, portfolioname }) => {
  const [isinvestModal, setisinvestModal] = useState(false);
    return (
      <>
        <div className=" scrollViewMain scrollDashboard investmentPage">
            <Card className="investmentDashboardMain">
                <div className="InvestmentHeader d-flex dateAccountNo">
                    <h2>  <span className="date CkNew">{asofdate && `${'Last Updated: ' + asofdate}` }</span></h2>
                    <h2>  <span className="date CkNew">{dwAccountNumber} </span></h2>

                </div>
                <Row className="InvestmentsCard" gutter={5}>
                    <Col className="" span={8}>
                        <div className="InvestMentCardInner OrangeBgGradiant">
                            <h3>Total Market Value</h3>
                            <div className="d-flex counterTitle">
                                <img src={require('../../../assets/images/investment_whit_value.png')} alt={'eligible image'} />
                                <h2>{dashboardData.investment_value ? '$' + dashboardData.investment_value : <div className="whiteSpinner"><Spin tip="Loading..." /></div>}</h2>
                            </div>
                        </div>
                    </Col>
                    <Col className="" span={8}>
                        <div className="InvestMentCardInner PurpilshBgGradiant">
                            <h3>Today's Change</h3>
                            <div className="d-flex counterTitle">

                                <img src={require('../../../assets/images/return-on-investment_white.png')} alt={'eligible image'} />
                                {/* <h2><>{dashboardData.investment_roi ? '$' + dashboardData.investment_roi : dashboardData.investment_roi == 0 ? '$' + '0.00' : <div className="whiteSpinner"><Spin tip="Loading..." /></div>}</></h2> */}
                                {Investmentdata?.unrealizedPL_total ? <h2 >   <span>{Investmentdata?.unrealizedDayPLPercent_total + '%'}<span style={{ fontSize: '12px' }}> / ${Investmentdata?.unrealizedPL_total}</span></span></h2> : <div className="whiteSpinner"><Spin tip="Loading..." /></div>}
                            </div>
                        </div>
                    </Col>
                    <Col className="" span={8}>
                        <div className="InvestMentCardInner bluishBgGradiant">
                            <h3>Available to Invest</h3>
                            <div className="d-flex counterTitle">
                                <img src={require('../../../assets/images/piggy-bank_white.png')} alt={'eligible image'} />
                                <h2><>{dashboardData.available_to_invest ? '$' + dashboardData.available_to_invest : <div className="whiteSpinner"><Spin tip="Loading..." /></div>}</> </h2>
                            </div>
                        </div>
                    </Col>
                    {
                        /**
                         <Col className="" span={6}>
                         <Button className="ExpandViewBtn investmorebtn" onClick={() => setisinvestModal(true)}>Invest More</Button> 
                        <div className="InvestMentCardInner primary2 position-relative">
                            <h3>Last Quarter</h3>
                            <div className="d-flex counterTitle">
                                <img src={require('../../../assets/images/profits_white_incre.png')} alt={'eligible image'} />
                                <h2>2.4%</h2>
                            </div>
                        </div>
                    </Col>
                         */
                    }
                    
                </Row>

                <Row className="mb0 investmentsCenterData" style={{ display: 'none' }}>
                    <Col span={24}>
                        <div className="card cardWhite heightAuotoCard">
                            <Row className="">
                                <Col span={16}>
                                    <Card>
                                        <div className="barChartHeader d-flex">
                                            <div className="timesinfor">
                                                {/* <a onClick={() => settypeofchart('day')} className={typeofchart == 'day' && "active"}>Day</a> */}
                                                <a onClick={() => settypeofchart('week')} className={typeofchart == 'week' && "active"}>Week</a>
                                                <a onClick={() => settypeofchart('month')} className={typeofchart == 'month' && "active"}>Month</a>
                                                <a onClick={() => settypeofchart('year')} className={typeofchart == 'year' && "active"}>Year</a>
                                            </div>
                                            <span className="threeDotMenu"></span>
                                        </div>
                                        <InvestmentDashboardLineChart typeofchart={typeofchart} />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card>
                                        <InvestmentDashboardDonutChart />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="mb0 investmentBotomTable">
                    <Col span={24} className="allocationTable mt0 investedPortfolio">
                        <div className="card cardWhite heightAuotoCard">
                            <h2>{portfolioname ? 'Portfolio Allocations: '+portfolioname : 'Portfolio Allocations'}</h2>
                            <GridListTables data={investmentequity} equityCategory={equityCategory} />
                            {/* <ManageDashboard listarr={investmentequity} /> */}

                        </div>
                    </Col>
                </Row>
                <AdvCrs />
            </Card>
        </div>
        <InvestModal
            isinvestModal= {isinvestModal}
            setisinvestModal={setisinvestModal}
            investData={Investmentdata}
            contributionSummary={contributionSummary}
        />
        </>
    )
}

export default InvestmentOverview;

import React from 'react'
import Chart from 'react-apexcharts'
import { Select } from "antd";
import { ApexOptions } from 'apexcharts';

export default function ExpenseChart(props) {
    const { summaryData } = props;

    let $primary = "#6666CE",
        $success = "#00BEE5",
        $danger = "#FFB929",
        $warning = "#3BE8B0",
        $info = "#2D3B55"

    let themeColors = [$primary, $success, $danger, $warning, $info]
    const options  = {
        series: [{
            name: 'Stock',
            data: [75]
          }, 
        //   {
        //     name: 'Total Pharmacy',
        //     data: [400]
        //   }, {
        //     name: 'Total doctor fees',
        //     data: [600]
        //   },
          {
            name: 'Total Other fees',
            data: [25]
          }
        ],
        chart: {
            width: "100%",
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar:
            {
              show:false
            }
          },

          colors: themeColors,
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
              bar: {
                horizontal: true,
              }
            },
          xaxis: {
              categories: [2022],
              labels: {
                  show: true,
                  formatter: function (val) {
                  return val 
                  }
              }
          },
          yaxis: {
              title: {
                  text: undefined
              },
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50,
          },
    };

    return (
        <>
            <div className="card cardWhite heightAuto expenseChart">
                <div className="card-body">

                    <br />
                    <div className="WhiteCardContent">
                        <div className="donutChart">
                            <div className="donutChartCenter">
                                <h2>Portfolio Allocation</h2>
                                <Chart
                                    options={options}
                                    series={options.series}

                                    type="bar"
                                    height={125}
                                    width="100%"
                                />
                                <div className="donutCenter">
                                    {/* <h3>
                                    Portfolio <br></br>Assets
                                    </h3> */}
                                    <p>{'This Year'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="chartValueText">
                            <div className="chartValue">
                                <span
                                    className="colorBox"
                                    style={{ backgroundColor: $primary }}
                                ></span>
                                <span className="seriesName">{'Stock'}</span>
                                <span className="seriesAmount">
                                    
                                &nbsp;  75%
                                </span>
                            </div>
                            <div className="chartValue">
                                <span
                                    className="colorBox"
                                    style={{ backgroundColor: $success }}
                                ></span>
                                <span className="seriesName">{'Other'}</span>
                                <span className="seriesAmount">
                                    
                                  &nbsp;   25%
                                </span>
                            </div>
                            {/* <div className="chartValue">
                                <span
                                    className="colorBox"
                                    style={{ backgroundColor: $danger }}
                                ></span>
                                <span className="seriesName">{'Doctor Fees'}</span>
                                <span className="seriesAmount">
                                    
                                    15%
                                </span>
                            </div>
                            <div className="chartValue">
                                <span
                                    className="colorBox"
                                    style={{ backgroundColor: $warning }}
                                ></span>
                                <span className="seriesName">{'Other'}</span>
                                <span className="seriesAmount">
                                    
                                    50%
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* <div className="card-footer">
                    <div className="chartFooterList">
                        <div className="footLeftText">{'Average medical expense per month '}</div>
                        <div className="footLeftText">
                            <strong>  $500</strong>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

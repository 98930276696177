import { Action, SponsorActionType } from "../../../model";
import createReducer from "../createReducer";

export const dashboardReducer = createReducer({}, {
	[SponsorActionType.SPONSOR_GET_DASHBOARD](state, action) {
		return action.payload;
	},
	[SponsorActionType.SPONSOR_GET_PARTICIPANTS](state, action) {
		return action.payload;
	},	
	[SponsorActionType.SPONSOR_NOTIFICATIONS](state, action) {
		return {
			...state, 
			sponsor_notifications:action.payload
		};
	},
});

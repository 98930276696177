import { SponsorActionType } from "../../model";
import { notifyMe } from "../../helper";


export const SponsorUpdatePassword = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('change_password_inprofile_sponsor', data, getState().auth.access_token);
		return dispatch({
			type: SponsorActionType.SPONSOR_UPDATE_PASSWORD,
			payload: resp
		});
	} catch (err) {
		console.error('Update Password Error: ', err);
	}
};

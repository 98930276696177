import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import { Main as MainLayout } from '../layouts';
import { Sponsor as SponserLayout } from '../layouts';

const PrivateRoutes = () => {
  return (
    <Switch>
        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Dashboard')))}
            exact
            layout={SponserLayout}
            path="/sponsor/dashboard"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Participant')))}
            exact
            layout={SponserLayout}
            path="/sponsor/participants"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/ParticipantDetails')))}
            exact
            layout={SponserLayout}
            path="/sponsor/participantdetails/:id"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Documents')))}
            exact
            layout={SponserLayout}
            path="/sponsor/documents"
        />
        
        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Dashboard')))}
            exact
            layout={SponserLayout}
            path="/sponsor/dashboard"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Messages')))}
            exact
            layout={SponserLayout}
            path="/sponsor/messages"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Reports')))}
            exact
            layout={SponserLayout}
            path="/sponsor/reports"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Foryou')))}
            exact
            layout={SponserLayout}
            path="/sponsor/foryou"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Settings')))}
            exact
            layout={SponserLayout}
            path="/sponsor/settings"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Accounts')))}
            exact
            layout={SponserLayout}
            path="/sponsor/accounts"
        />

        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/Prefrences')))}
            exact
            layout={SponserLayout}
            path="/sponsor/preferences"
        />

        {/*Routes would go to admin panel forward*/}
        <RouteWithLayout
            component={lazy(() => (import('../views/sponsor/admin')))}
            exact
            layout={SponserLayout}
            path="/sponsor/admin"
        />
        
        <Redirect from='/' to='/sponsor/dashboard' />
    </Switch>
  )
};

export default PrivateRoutes;

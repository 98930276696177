import React, {useState, useEffect} from 'react';
import { Table, Select, Pagination, Row, Col, Card, Tabs, Button, Collapse ,  Checkbox, Carousel, Radio, Input, Slider, InputNumber,Modal,  SubmitButton,
    Form,
    FormItem,} from "antd";
import { useActions, investmentAction } from '../../actions';
import { notifyMe, Loader, LoaderStart } from "../../helper";

const InvestModal = ({isinvestModal, setisinvestModal, investData, contributionSummary}) => {

    console.log(investData, contributionSummary)

    const [AmountStr, setAmountStr] = useState('');
    const [fieldError, setfieldError] = useState('Amount must be greater than $1.00');
    const [issubmitting, setissubmitting] = useState(false);
    const investmentActions = useActions(investmentAction);
    const [loader, setloader] = useState(false);

    const handleChange = (value) => {
      setfieldError('');
      if (value != '' && isNaN(value)) {
        setAmountStr('');
        setfieldError('Amount cannot be empty');
        return false;
      }
      if (value <= 0) {
        setfieldError('Amount must be greater than $1.00');
        setAmountStr(value);
        return false;
      }

      if (parseFloat(value) > parseFloat(investData.investable_hsa_balance)) {
        setfieldError('Amount exceeds available balance');
        setAmountStr(value);
        return false;
      }
      setAmountStr((Math.round(value * 100) / 100).toFixed(2));
    }

    const transferMoney = async () => {
        if (parseFloat(AmountStr) > parseFloat(investData.investable_hsa_balance)) {
            setfieldError('Amount exceeds available balance');
            return false;
        }

        if (parseFloat(AmountStr) <= 0) {
            setfieldError('Amount must be greater than $1.00');
            return false;
        }

        const data = {
            amount: parseFloat(AmountStr)
        }
        setloader(true);

        let resp = await investmentActions.investmoney(data);
        console.log(resp)
        if(resp.payload.code != 400) {
            notifyMe({ key: 'getInvestment', method: 'success', message: 'Funds transfered successfully. You can only do 3 transfers in a 24-hour window.' });
            handleCancel();
            setloader(false);
            setTimeout(() => {
                window.location.reload(false);
            }, 3000);
            return false;
        } else {
            if(resp.payload.data == 'Failed Payment') {
                notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: 'You have already transferred 3 times in the 24 hour period. Please wait to make additional transfers.' });
                handleCancel();
                setloader(false);
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);            
                return false;
            } else {
                notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: 'There was an error in transferring the funds. Please try again later. If the problem persists, please contact support.' });
                handleCancel();
                setloader(false);
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);            
                return false;
            }
        }
    }

    const handleCancel = () => {
        setAmountStr('')
        setfieldError('')
        setissubmitting(false);
        setisinvestModal(false)
    }

    return (
        <>
        <Loader isBackdropVisible={loader} />
        <Modal title="Invest Funds" centered open={isinvestModal} onOk={() => handleCancel()} onCancel={() => handleCancel()} className="defaultForm antModal alertModel investmoreModal">
            <div className="manageCardsSliderButton">
                <div className="stepTabsFive">
                    <div className="HsaFundBalance ">
                    <div className="hsaFundBtn">
                        <h3>Cash Balance</h3>
                        <div className="hsaButtonBorder onlyBorder">
                        <span className="iconShopHSA"></span>
                        <span className="priceHSAGreen borderNone">$ {investData.investable_hsa_balance} </span>
                        </div>
                        <p>Current cash balance is <strong>${parseInt(investData.available_to_invest) + parseInt(1)}.</strong><br />
                        You are required to maintain a minimum<br />balance of ${investData?.minimum_investment_amount ? investData?.minimum_investment_amount : '1'} in your account.
                        </p>
                    </div>
                    <div className="hsaFundBtn active fundingAmountRightDiv">
                        <h3>Move to Investment</h3>
                        <div className="hsaButtonBorder">
                        <span className="iconShopHSA priceHSATree"></span>
                        <span className="priceHSAGreen "> <span className="dallarSign">&#x00024; &nbsp;</span>
                            <Form className="intialFund">
                            <div className={issubmitting ? "loaderMain" : "loaderMain  d-none"}>
                                <div className="loaderInner">
                                <img src={require('../../assets/images/SpinnerGreen.gif')} alt="logo" className="" />
                                <p>Please wait we are processing your information... Do not press Back or Refresh </p>
                                </div>
                            </div>
                            <InputNumber
                                type="text"
                                min="1"
                                name="dob"
                                placeholder="0.00"
                                step="0.00"
                                className="inputInitialFund"
                                defaultValue={AmountStr || ''}
                                onChange={handleChange}
                                value={AmountStr || ''}
                                controls={false}
                                bordered={false}
                                disabled={false}
                            />

                            {/* -------------------------   ----------------------Question-Setup-------------------------------------------- */}
                            <div className="loaderMain d-none">
                                <div className="loaderInner">
                                <img src={require('../../assets/images/SpinnerGreen.gif')} alt="logo" className="" />
                                <p>Please wait we are setting questions up... Do not press Back or Refresh </p>
                                </div>
                            </div>
                            {/* -------------------------------------Question-Setup-ends---------------------------------------------------- */}

                            </Form>
                        </span>
                        </div>
                        <p>This amount will be transferred from your HSA investment account to your investment account.<br /><strong>You must take into consideration near-term medical bills. </strong></p>
                        <div id="feedback error"> {fieldError != '' ? fieldError : ''} </div>
                    </div>
                    </div>
                    <div className="buttonsManageBottom">
                    <div className="investButtons">
                        <Button className="buttonLeftInvest activeBorder" onClick={() => handleCancel()}><span className="investIcon Left"></span> Close </Button>
                        <Button className="buttonLeftInvest active" onClick={transferMoney} disabled={fieldError ? true : false}> Invest <span className="investIcon Right" ></span></Button>
                    </div>
                    </div>
                </div>
                </div>
        </Modal>
        </>
    )
}

export default InvestModal;

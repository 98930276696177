import { SponsorActionType } from "../../model";
import { notifyMe } from "../../helper";

export const getfinchAccessToken = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('get_finch_access_token_by_code', data, '', getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_FINCH_ACCESS_TOKEN, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getfinchaccess', method: 'error', message: 'Error', description: error });
	}
};

export const getcompanydata = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('get_company_data', data, '', getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_FINCH_COMPANY_DATA, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getfinchcompany', method: 'error', message: 'Error', description: error });
	}
};

export const getdirectorydata = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('get_directory_data', data, '', getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_FINCH_DIRECTORY_DATA, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getfinchdirectory', method: 'error', message: 'Error', description: error });
	}
};

export const getpaymentdata = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('get_payment_data', data, '', getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_FINCH_PAYMENT_DATA, payload: resp});
	} catch (error) {
		notifyMe({ key: 'getfinchpayment', method: 'error', message: 'Error', description: error });
	}
};


export const getcompanydetails = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('getcompanydetails', data, '', getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_FINCH_DATA, payload: resp});
	} catch (error) {
		notifyMe({ key: 'getfinchpayment', method: 'error', message: 'Error', description: error });
	}
};
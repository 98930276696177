import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as authAction from './auth';
import * as transAction from './transactions';
import * as contrAction from './contributions';
import * as myProfileAction from './myProfile'
import * as beneficiariesAction from './beneficiaries';
import * as dependentsAction from './dependents';
import * as investmentAction from './investments';
import * as bankAction from './bank';

/*Sponsor auth actions*/
import * as authSponsorAction from './sponsor/authSponsor';
import * as getDashboardAction from './sponsor/dashboard';
import * as getParticipantAction from './sponsor/participant';
import * as getStatesAction from './sponsor/common';
import * as getDocumentsAction from './sponsor/documents';
import * as SponsorUpdatePassword from './sponsor/sponsorProfile';
import * as Prefrences from './prefrence';
import * as finchAction from './sponsor/finch';

export function useActions(actions: any): any {
	const dispatch = useDispatch();
	return useMemo(
		() => {
			if (Array.isArray(actions)) {
				return actions.map(a => bindActionCreators(a, dispatch));
			}
			return bindActionCreators(actions, dispatch);
		},
		[dispatch, actions]
	);
}

export {
	authAction,
	transAction,
	contrAction,
	myProfileAction,
	beneficiariesAction,
	dependentsAction,
	authSponsorAction,
	getDashboardAction,
	getParticipantAction,
	getStatesAction,
	SponsorUpdatePassword,
	getDocumentsAction,
	Prefrences,
	investmentAction,
	bankAction,
	finchAction
}

import React, { useEffect, useState, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { Formik } from 'formik';
import {
  Input,
  Form,
  FormItem,
} from "formik-antd";
import { Button, Spin, Card} from "antd";
import { isRequired, validateEmail } from '../../validations';
import { useActions, authAction, getStatesAction, bankAction } from "../../actions";
import './createAccount.css';
import { PlaidLink } from "react-plaid-link";
import { Utils } from "../Utils/utils";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const initialValues = {
  username: '',
  password: ''
};

const LinkAccount = (props) => {
  const stateAction = useActions(getStatesAction);
  const [linkedAccounts, setLinkedAccounts] = useState();
  const [AccountAdeded, AccountAdededShow] = useState(false);
  const [linktoken, setlinktoken] = useState(null);
  const [checkAccessToken, setCheckAccessToken] = useState(false);
  const [isBackdropVisible, setBackdropVisibility] = useState(true);
  const [loader, setLoader] = useState(false);
  
  useEffect(() => {
    _fetchaccessToken();
  }, []);

  const _fetchaccessToken = async () => {
    let resp2 = await stateAction.checkAccessToken();
    if (resp2.payload.message == "Success") {
      setCheckAccessToken(true);
      let resp1 = await stateAction.checkLinkedBankAccounts();
      setLinkedAccounts(resp1.payload);
      setBackdropVisibility(false);
      if (resp1.payload) {
        AccountAdededShow(true);
      }
    } else {
      let resp = await stateAction.createlinktoken();
      if (resp) {
        setlinktoken(resp?.link_token);
        setBackdropVisibility(false);
      }
    }
  };

  const onSuccess = useCallback(async (public_token, metadata) => {
    // send public_token to server
    setLoader(true);
    const publicToken = {
      public_token: public_token,
    };
    let resp2 = await stateAction.postPublicToken(publicToken);
    setBackdropVisibility(false);
    if (resp2) {
      window.localStorage.removeItem('bankadded');
      props.history.push('/dashboard');
    }
  }, []);

  return (
      <div className="bank-screen create-account-div">
        <div className="logo-text-section">
          <div>
            <h3 className="t-center">{Utils.linkbankinformation}</h3>
            <div className="bank-logo">
                 <img src="/assets/images/deposit.png" alt={"Logo"} />
             </div>
             <div className="card heightAuto addbank">
               <div className="card-body">
                 <h4 className="subTitle">{Utils.bankaccount}</h4>
                 <p>
                 {Utils.expeditedreimbursements}
                 </p>
                 {
                   loader == true
                   ?
                    <div className="spinnerCont">
                      <Spin tip="Loading..." />
                    </div>
                   :
                   <PlaidLink
                     className="buttonBorder"
                     token={linktoken}
                     onSuccess={onSuccess}
                     style={{ padding: "12px 24px" }}
                   >
                    {Utils.newaccount}
                   </PlaidLink>

                 }

               </div>
             </div>
          </div>
        </div>
        <div className="clearFix"></div>
      </div>
  );
};

export default withRouter(LinkAccount);

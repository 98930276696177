import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { Radio, Input, Row, Col, Slider } from "antd";
import { InfoCircleOutlined, UserOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Drawer, Card, Image, Space, Tag, Tabs, Divider } from 'antd';

const FilterComponent = ({ data, slider, filterValue }, props) => {
  const [value4, setValue4] = useState("CardView");


  const onChange4 = (e) => {
    console.log("radio4 checked", e.target.value);
    setValue4(e.target.value);
  };

  const plainOptions = ["CardView", "ListView"];

  const cardViewListView = [
    { label: "Card View", value: "CardView" },
    { label: "List View", value: "ListView" },
    // { label: 'Orange', value: 'Orange', disabled: true },
  ];
  const [girdClass, setGirdClass] = useState(20);
  let gridWidth = 20;
  if (slider > 0 && slider < 20) {
    gridWidth = 20;
  }
  if (slider > 20 && slider < 30) {
    gridWidth = 25;
  }
  if (slider > 30 && slider < 50) {
    gridWidth = 33.333;
  }
  if (slider > 50 && slider < 80) {
    gridWidth = 50;
  }
  if (slider > 80 && slider < 100) {
    gridWidth = 100;
  }
  const { value, onChangeFunction } = props;
  const [currentValue, changeValue] = useState(value);
  const [gridLayout, setGridLayout] = useState(true);
  const onChange = (newValue) => {
    //onChangeFunction();
    changeValue(newValue);
    if (newValue > 40) {
      setGridLayout(false);
    } else {
      setGridLayout(true);
    }
  };

  const [visible, setVisible] = useState(false);
  const [modalValue, setModelValue] = useState('')
  const showDrawer = (val) => {
    console.log('val', val);
    setModelValue(val)
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="ComapnyCardWrappersMain cardview">

        {data?.map((val, i) => (
          <div
            key={i}
            className="CompanyCardViewItm"
            style={{ width: "calc(" + gridWidth + "% - .8rem)" }}
          >
            <div className="companyCardHeader">
              <div className="brandinfo">
                {
                  val && val.image
                  ?
                  <div className="brandLogo">
                    <img src={val && val.image} />
                  </div>
                  :
                  ""
                }
                <div className="brandName">
                  <a href={val && val.research} target="_blank">
                    <h4>{val && val.ticker}</h4>
                  </a>
                </div>
              </div>
              <div className="settingButtons">
                {/* <a href={val.research} target="_blank" className="PlusBtn"></a> */}
                <a className="viewBtn" onClick={() => showDrawer(val)}></a>
              </div>
            </div>
            <div className="CopanyTitle">
              <h5>{val && val.name}</h5>
            </div>
            <div className="companyCardFooter">
              <div className="price">
                <span>Cost Basis</span>
                <h6>{val && val.cost ? "$" + val.cost : "-"}</h6>
              </div>
              <div className="today">
                <span>Mkt Value</span>
                <h6>

                  {val && val.marketvalue ? (
                    <>
                      {parseFloat(val.unrealizedPL) < 0 ? <ArrowDownOutlined style={{ color: '#DA100B', fontSize: '16px' }} /> : <ArrowUpOutlined style={{ color: '#05a772', fontSize: '16px' }} />}

                      &nbsp;{ "$" + val.marketvalue }
                    </>
                  ) : (
                    "-"
                  )}
                </h6>
              </div>
            </div>
          </div>
        ))}

        {/**TODO :- MAKE ONE COMPONENT AFTER DEMO */}
        <Drawer width='670'
          placement="right"
          onClose={onClose}
          open={visible}
          closable={false}
        >

          <Card className="self-directed-overview">
            <Space direction="horizontal" className="space-between">
              <Image src={modalValue?.image} alt="" style={{ maxWidth: '185px', width: '100%', height: '90px' }} preview={false} />
              <h2>{modalValue?.ticker}</h2>
            </Space>
          </Card>
          <Divider />
          <h3 className="overlay-text">{modalValue?.name}</h3>
          <Space direction="horizontal" className="space-between">
            <Card className="ishare-card1">
              <span>Today's Change</span>
              <h4 className="mb-0">${modalValue?.unrealizedPL}
                {/* <Tag className="value-change">+2.5%</Tag> */}
                <Tag className="value-reflect1">{modalValue?.unrealizedPL < 0 ?  <ArrowDownOutlined /> :<ArrowUpOutlined />}</Tag>
              </h4>
            </Card>

            <Card className="ishare-card2">
              <span> Market Value</span>
              <h4 className="mb-0">${modalValue?.marketvalue}
                {/* <Tag className="value-change">+2.5%</Tag> */}
                <Tag className="value-reflect2">{modalValue?.unrealizedPL < 0 ?  <ArrowDownOutlined /> :<ArrowUpOutlined />}</Tag>
              </h4>
            </Card>
          </Space>
          {/*** TODO :- UNCOMMENT AFTER DEMO*/}
          {/* <div className="self-directed-overview-tabs">
            <Tabs defaultActiveKey="2">
              <TabPane tab="Buy" key="1">

              </TabPane>
              <TabPane tab="Review" key="2">

              </TabPane>
              <TabPane tab="Complete" key="3">

              </TabPane>
            </Tabs>
          </div> */}
        </Drawer>
      </div>
    </>
  );
};

export default withRouter(FilterComponent);

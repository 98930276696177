export interface IRequestParams {
    method: string;
    url: string;
    queryParameters?: { [key: string]: string | boolean | number | Date | undefined };
    body?: Object;
    accessToken?: string;
  }

  export abstract class ApiService {

    protected getQueryString(params: any) {
      const esc = encodeURIComponent;
      return Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
    }

    protected async executeRequest<T>(params: IRequestParams) {
      return new Promise<T>((resolve, reject) => {
        let fetchUrl = params.url;
        const method = params.method || 'GET';
        let body;
        let headers = new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        });

        if (JSON.stringify(params.queryParameters) !== '{}') {
          fetchUrl += '?' + this.getQueryString(params.queryParameters);
        }

        if (params.body) {
          body = JSON.stringify(params.body);
        }

        if (params.accessToken) {
          headers.append('Authorization', 'Bearer ' + params.accessToken);
        }

        const myRequest = { method, body, headers };

        fetch(fetchUrl, myRequest)
        .then((result: any) => {
          if(result.status == '401') {
            let path_name = window.location.pathname;
            if(path_name.indexOf("sponsor") > -1) {
              window.localStorage.clear();
              window.location.href = '/sponsor/signIn';
            } else {
              window.localStorage.clear();
              window.location.href = '/signIn';
            }
          } else {
            resolve(result.json());
          }
        }).catch((error: any) => {
          reject(error);
        });
      });
    }
  }

  export class Api extends ApiService {
    baseApiUrl: string;
    constructor(url: string) {
      super()
      this.baseApiUrl= url;
    }

    public async get(url: string, query: any = {}, accessToken: string = '') {

      const requestParams: IRequestParams = {
        method: 'GET',
        url: `${this.baseApiUrl}/${url}`,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }

    public async post(url: string, data: any = {}, query: any = {}, accessToken: string = '') {
      const requestParams: IRequestParams = {
        method: 'POST',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }

    public async put(url: string, data: any = {}, query: any = {}, accessToken: string = '') {
      const requestParams: IRequestParams = {
        method: 'PUT',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
    public async patch(url: string, data: any = {}, query: any = {}, accessToken: string = '') {
      const requestParams: IRequestParams = {
        method: 'PATCH',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        queryParameters: query,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
    public async delete(url: string, data: any = {}, accessToken: string = '') {
      const requestParams: IRequestParams = {
        method: 'DELETE',
        url: `${this.baseApiUrl}/${url}`,
        body: data,
        accessToken: accessToken
      };
      return this.executeRequest(requestParams);
    }
  }

import { Action, SponsorActionType } from "../../../model";
import createReducer from "../createReducer";

export const ParticipantReducer = createReducer({}, {
	[SponsorActionType.SPONSOR_GET_PARTICIPANTS](state, action) {
		return action.payload;
	},
	[SponsorActionType.SPONSOR_INDIVISUAL_DATA](state, action) {
		return {
			...state, 
			indivsualdata:action.payload
		};
	},
	[SponsorActionType.SPONSOR_PARTICIPANT_PAGINATE](state, action) {
		return {
			...state,
			paginationData:action.payload
		};
	},
	[SponsorActionType.SPONSOR_PARTICIPANT_SEARCH](state, action) {
		return {
			...state,
			searchResults:action.payload
		};
	},
	[SponsorActionType.SPONSOR_PARTICIPANT_CONTRIBUTIONHISTORY](state, action) {
		return {
			...state,
			indvisualhistory:action.payload
		};
	},
});

import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const dependents = createReducer({}, {
	[ActionType.LIST_DEPENDENTS](state: any, action: Action<any>) {
		return action.payload;
	},	
});

export const addDependent = createReducer({}, {
	[ActionType.ADD_DEPENDENT](state: any, action: Action<any>) {
		return action.payload;
	},	
});


// export const editDependent = createReducer({}, {
// 	[ActionType.EDIT_DEPENDENT](state: any, action: Action<any>) {
// 		return action.payload;
// 	},	
// });


export const delDependent = createReducer({}, {
	[ActionType.DEL_DEPENDENT](state: any, action: Action<any>) {
		return action.payload;
	},	
});
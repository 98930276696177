import { combineReducers } from "redux";

import * as authReducer from "./auth";
import * as accountReducer from "./account";
import * as transactionsReducer from "./transactions";
import * as contributionReducer from "./contributions";
import * as myProfileReducer from "./myprofile";
import * as beneficiariesReducer from "./beneficiaries";
import * as dependentsReducer from "./dependents";
import * as investmentsReducer from "./investments";
import * as contributionReducerNew from "./contributions";
import * as bankReducer from "./bank";

/*Sponsor auth reducers */
import * as sponsorauthReducer from "./sponsor/authSponsor";
import * as dashboardReducer from "./sponsor/dashboard";
import * as SponsorStates from "./sponsor/common";
import * as ParticipantReducer from "./sponsor/participant";
import * as DocumentsReducer from "./sponsor/documents";
import * as SponsorUpdatePassword from './sponsor/sponsorProfile'
import * as checkLinkedBankAccounts from './sponsor/common'
import * as checkAccessToken from './sponsor/common'
import * as getCashStatment from './sponsor/common'
import * as getInvestmentStatment from './sponsor/common'
import * as getInvestmentFileKey from './sponsor/common';
import * as getDwTransactions from './sponsor/common'
import * as participantNotificationStatus from './sponsor/common'
import * as sendNotificationEmail from './sponsor/common';
import * as finchReducer from './sponsor/finch';




export interface RootState {
	auth: any;
	account: any;
	transactions: any;
	contributions: any;
	contributionshis: any;
	contributionsexpense: any;
	myProfile: any;
	beneficiaries:any;
	dependents:any;
	authReducer: any;
	sponsorauth: any;
	sponsorauthReducer: any;
	dashboardReducer: any;
	ParticipantReducer:any;
	SponsorStates: any;
	SponsorUpdatePassword: any;
	DocumentsReducer: any;
	investmentsReducer: any;
	checkLinkedBankAccounts: any,
	checkAccessToken:any,
	contributionReducerNew:any,
	getCashStatment:any,
	getInvestmentStatment:any,
	getInvestmentFileKey:any,
	getDwTransactions:any,
	participantNotificationStatus:any,
	sendNotificationEmail:any,
	bankReducer:any,
	finchReducer: any
}

export default () =>
	combineReducers({
		...authReducer,
		...accountReducer,
		...transactionsReducer,
		...contributionReducer,
		...myProfileReducer,
		...beneficiariesReducer,
		...dependentsReducer,
		...sponsorauthReducer,
		...dashboardReducer,
		...ParticipantReducer,
		...SponsorStates,
		...SponsorUpdatePassword,
		...DocumentsReducer,
		...investmentsReducer,
		...checkLinkedBankAccounts,
		...checkAccessToken,
		...contributionReducerNew,
		...getCashStatment,
		...getInvestmentStatment,
		...getInvestmentFileKey,
		...getDwTransactions,
		...participantNotificationStatus,
		...sendNotificationEmail,
		...bankReducer,
		...finchReducer
	});

import { SponsorActionType } from "../../model";
import { notifyMe } from "../../helper";

export const getParticipantData = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_participants', data,  getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_GET_PARTICIPANTS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getSponsorParticipantPaginate = (endPoint) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_participants' + endPoint, {}, getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_PARTICIPANT_PAGINATE, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getSponsorParticipantSearch = (queryParams) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_participants', queryParams, getState().sponsorauth.access_token);
		return dispatch({ type: SponsorActionType.SPONSOR_PARTICIPANT_SEARCH, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const getparticipantcontributionhistory = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_contribution_info_statements_by_individual_id', data, 
		 getState().sponsorauth.access_token, 'sponsor');
		return dispatch({ type: SponsorActionType.SPONSOR_PARTICIPANT_CONTRIBUTIONHISTORY, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};
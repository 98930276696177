import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, Table, Select, Spin } from 'antd';
import Chart from "react-apexcharts";

const InvestmentDashboardLineChart = ({typeofchart}) => {

    let $primary = "#37C597",
        $success = "rgba(254, 154, 32, 0.64)",
        $danger = "rgba(45, 59, 85, 0.64)"
   

    let themeColors = [$primary, $success, $danger]

    let earningdata = [];
    let contributiondata = [];
    let categories = [];

    if(typeofchart == 'month' || typeofchart == 'year') {
      earningdata = [10, 1000, 1500, 5000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000];
      contributiondata = [11, 1500, 3000, 2500, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000];
      if(typeofchart == 'month') {
        categories= ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      } else if (typeofchart == 'year') {
        categories= ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021'];
      }
      
    } else {
      earningdata = [10, 1000, 1500, 5000, 4000, 5000, 6000];
      contributiondata = [11, 1500, 3000, 2500, 4000, 5000, 6000];
      categories= ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    }

    const series = [{
        name: 'Earnings',
        data: earningdata
      },
      {
        name: 'Contributions',
        data: contributiondata
      }];

    const options = {
      chart: {
          height: 250,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          row: {
            colors: [ 'transparent'], // takes an array which will be repeated on columns
            opacity: 0
          },
        },
        xaxis: {
          categories: categories,
        }
    };
  
  return (
    <>
      <div id="chart" className="InvestmentChart">
        <Chart options={options} series={series} type="area" height={300} />
      </div>
    </>
  );
};

export default withRouter(InvestmentDashboardLineChart);

import { ActionType } from "../model";
import { notifyMe } from "../helper";



export const DependentList = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_dependents', data,  getState().auth.access_token);
		return dispatch({
			type: ActionType.LIST_DEPENDENTS,
			payload: resp.data
		});
	} catch (err) {
		console.error('dependents Error: ', err);
	}
};


export const AddDependent= (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('add_dependent', data, '',  getState().auth.access_token);
		return dispatch({
			type: ActionType.ADD_DEPENDENT,
			payload: resp
		});
	} catch (err) {
		console.error('ADD Dependent Error: ', err);
	}
};


export const EditDependent= (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('edit_dependent', data, '',   getState().auth.access_token);
		return dispatch({
			type: ActionType.EDIT_DEPENDENT,
			payload: resp
		});
	} catch (err) {
		console.error('Edit Dependent Error: ', err);
	}
};


export const DelDependent= (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('delete_dependent', data, '', getState().auth.access_token);
		return dispatch({
			type: ActionType.DEL_DEPENDENT,
			payload: resp
		});
	} catch (err) {
		console.error('del Dependent Error: ', err);
	}
};

export const getIndividualDependentsCategoriesForExpenses = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_individual_dependents_categories_for_expenses', data, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_INDIVIDUAL_DEPENDENTS_CATEGORIES_FOR_EXPENSE, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
	}
};

export const addIndividualExpenses = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('add_individual_expenses', data, '',   getState().auth.access_token);
		return dispatch({ type: ActionType.ADD_INDIVIDUAL_EXPENSE, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
	}
};

import React from 'react';
import { Row, Col } from "antd";

interface MinimalProps {
  children: any,
}

const Minimal = (props: MinimalProps) => {
  const { children } = props;

  return (
    <div className="login-page">
      <div className="container-fluid full-page">
        <Row>
          <Col xs={0} sm={0} md={0} lg={12} xl={12} className="greenBg">
            <div className="login-img"></div>
          </Col>
          <Col  xs={24} sm={24} md={24} lg={12} xl={12} >
           <div className="auth-container">
           <div className="login-logo">
                <img src="/assets/images/logo/SO_Logo_Green.png" alt={"Logo"} />
            </div>
            {children}
           </div>
        </Col>
        </Row>
      </div>
    </div>
  );
};

export default Minimal;

import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Topbar = (props) => {

  let title = '';

  let arr = window.location.pathname.split('/');

  title = (arr[2] === 'dashboard') ? 'home' : arr[2];

  if(title == 'foryou') {
    title = 'For You';
  } else if (title == 'accounts') {
    title = 'Profile';
  } else if (title == 'participantdetails') {
    title = 'Participant Details';
  }

  if(props?.ParticipantReducer?.indivsualdata?.middle_name != undefined && title == 'Participant Details') {
    title = 'Participant Details: ' + props?.ParticipantReducer?.indivsualdata?.first_name + ' ' + props?.ParticipantReducer?.indivsualdata?.middle_name + ' ' + props?.ParticipantReducer?.indivsualdata?.last_name;
  } else if (props?.ParticipantReducer?.indivsualdata?.first_name != undefined && title == 'Participant Details') {
    title = 'Participant Details: ' + props?.ParticipantReducer?.indivsualdata?.first_name +  ' ' + props?.ParticipantReducer?.indivsualdata?.last_name;
  }

  const capitalize = (value) => value && value[0].toUpperCase() + value.slice(1);

  return (
    <>
      <div className="topNavBar" >
      <h1 className="headingTop">{capitalize(title)}</h1>
      {/* <h1 className="headingTop">Home</h1> */}

        {/* <div className="topNavRight">
          <Link to="/" className="buttonMain">Add Expense</Link>
        </div> */}
      </div>
    </>
  );
};

// export default Topbar;
function mapStateToProps(state) {
  return {
    sponsorauth: state.sponsorauth,
    ParticipantReducer: state.ParticipantReducer
  };
}
export default connect(mapStateToProps)(Topbar);
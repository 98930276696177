import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const account = createReducer({}, {
	[ActionType.ACTIVATE_ACCOUNT](state: any, action: Action<any>) {
		return action.payload;
	},
	// [ActionType.VERIFY_ACCOUNT](state: any, action: Action<any>) {
	// 	return action.payload;
	// },
	// [ActionType.FORGOT_PASSWORD](state: any, action: Action<any>) {
	// 	return action.payload;
	// },
	// [ActionType.CHECK_VERIFICATION_TOKEN](state: any, action: Action<any>) {
	// 	return action.payload;
	// },
	// [ActionType.CHANGE_PASSWORD](state: any, action: Action<any>) {
	// 	return action.payload;
	// },
	// [ActionType.RESEND_VERIFICATION_MAIL](state: any, action: Action<any>) {
	// 	return action.payload;
	// },	
});

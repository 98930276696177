import React, { useState, useEffect } from 'react'
import {
  Table,
  Select,
  Pagination,
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Collapse,
  Input,
  Checkbox,
  Carousel,
  Radio,
  Breadcrumb,
  Steps,
  message,
  Spin,
  InputNumber,
} from "antd";
import ApexCharts from 'apexcharts';
import moment from 'moment';
import FundDistributionChart from './charts/FundDistributionChart';











const { TabPane } = Tabs;



const columns = [
  {
    // title: (filters, sortOrder) => 'Date', dataIndex: 'date', sorter: { compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(), multiple: 6, },
    title: (filters, sortOrder) => ' Fund/Ticker Name ', dataIndex: 'tickername', sorter: {
      compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(), multiple: 6,

    },
    render: (tickername) => (
      <>
        <strong className="fc-blue">VTINX</strong>
        <div className="ASDF fc-blue">{tickername}</div></>
    )
  },
  {
    title: (filters, sortOrder) => ' YTD Return ', dataIndex: 'ytdreturn', sorter: { compare: (a, b) => a.merchantName.localeCompare(b.merchantName), multiple: 5, },
    render: (merchantName) => <span>{merchantName}</span>
  },
  {
    title: (filters, sortOrder) => '1 YR Return', dataIndex: 'oneYreturn', sorter: { compare: (a, b) => a.type.localeCompare(b.type), multiple: 4, },
  },
  {
    title: (filters, sortOrder) => '5 YR Return', dataIndex: 'fiveYrreturn', sorter: { compare: (a, b) => a.type.localeCompare(b.type), multiple: 3, },
  },
  {
    title: (filters, sortOrder) => 'Select Election %', dataIndex: 'election', sorter: { compare: (a, b) => a.amount - b.amount, multiple: 2, },
    render: (election) => <InputNumber className="SelectElection" min={1} max={100} defaultValue={10} formatter={value => `${value}%`}
    />

  },
  {
    title: (filters, sortOrder) => 'Price', dataIndex: 'price', sorter: { compare: (a, b) => a.status.localeCompare(b.status), multiple: 1, },
    render: (status) => <span>{status}</span>
  },
];



const data = [
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },
  {
    key: 1,
    tickername: "Vanguard Target Retirementv Income Fund Investor",
    ytdreturn: '2.86',
    oneYreturn: "3.39",
    fiveYrreturn: "5.29",
    election: "20% ",
    price: " $450.00",
  },







];




const Transactions = (props) => {
  const [transArr, setTransArr] = useState([]);
  const [rows, setRows] = useState(10);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [page, setPage] = useState(0);

  const showTotal = (total) => `Total ${total} transactions`;
  const expandedRowRender = (record) =>
    <table className="transactionData">
      <tr>
        <td width="45">&nbsp;</td>
        <td>
          <h2>Transaction Details</h2>
          <div className="listingData">
          </div>
        </td>
      </tr>
    </table>;
  const onShowSizeChange = (current, pageSize) => {
    setPage(current);
    setRows(pageSize);
  };
  const onPaginationChange = (pageNumber) => setPage(pageNumber);

  return (
    <>

      <div className="containerNew ScrollViewMain scrollDashboard moveMoneyWrapper">
        <Card className="TransactionMain">
          <Breadcrumb className="FundTrabsferBreadcrumb" separator=">">
            <Breadcrumb.Item>Transactions</Breadcrumb.Item>
            <Breadcrumb.Item>Fund Distribution  </Breadcrumb.Item>
          </Breadcrumb>
          <Row className="row">
            <Col span={12} className="col-md-8">
              <div className="investBannerText pt0">
                <div>
                  <h2 className="investSubHead">
                    Fund Distribution
                  </h2>
                  <p>
                    Select the funds and distribute your investment balance into them. The total amount should equal 100%.
                    Phasellus eleifend purus justo, a semper velit pretium ut. Nunc lobortis lacus et dignissim sagittis. Donec fermentum quis erat quis posuere. Nam congue dapibus ante, et ornare odio. Suspendisse erat nunc, efficitur et urna non, molestie pellentesque nunc.
                  </p>
                </div>
              </div>
            </Col>


            <Col span={12} >
              <div className="fundDirstibutionImg d-flex">
                <Col span={12} >
                  <div className="DisInImg bc-blue">
                    {/* <img src={require('')} /> */}

                    <span className="iconShopHSA"></span>
                    <h4>Amount Investing</h4>
                    <h2>$1,500.00</h2>
                  </div>
                </Col>
                <Col span={12} >
                  <div className="DisInImg bc-primary disChartMain">
                    <FundDistributionChart />
                  </div>
                </Col>

              </div>
            </Col>



          </Row>



          <Row>
            <Col span={24}>


              <Table
                className="paginationDisable "
                columns={columns}

                dataSource={data}
                pagination={false}
              />

              <Pagination
                className="customPagination"
                defaultCurrent={1}
                pageSize={rows}
                total={totalTransaction}
                showTotal={showTotal}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onPaginationChange}
              />

            </Col>
          </Row>
          <p className="totalAllocate">Total Allocated:  100%</p>
          <div className="allocatedbtn">
            <Button className="buttonLeftInvest activeBorder" >   Cancel  </Button>
            <Button type="primary" className="buttonLeftInvest active">   Review Oder </Button>
          </div>
        </Card>
      </div>

    </>
  )
}

export default Transactions

import { ActionType } from "../model";
import { notifyMe } from "../helper";

export const getemailprefrence = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_individual_notification_preferences', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_EMAIL_PREFRENCES, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const updatesponsoremailprefrences = (data) => async (dispatch, getState, api) => {
	try {
        let resp = await api.post('update_individual_notification_preferences', data, '', getState().auth.access_token);
        if (resp.code == '200') {
            return {'status': '200', 'message': 'Email prefrence status updated'};
        } else {
            return {'status': '200', 'message': 'Error in updating, Please contact admin'};
        }
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

/*Sponsor*/

export const getsponsoremailprefrence = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_sponsor_notification_preferences', {}, getState().sponsorauth.access_token);
		return dispatch({ type: ActionType.GET_EMAIL_PREFRENCES, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};

export const updateprefrences = (data) => async (dispatch, getState, api) => {
	try {
        let resp = await api.post('update_sponsor_notification_preferences', data, '', getState().sponsorauth.access_token);
        if (resp.code == '200') {
            return {'status': '200', 'message': 'Email prefrence status updated'};
        } else {
            return {'status': '200', 'message': 'Error in updating, Please contact admin'};
        }
	} catch (error) {
		notifyMe({ key: 'getTrans', method: 'error', message: 'Error', description: error });
	}
};



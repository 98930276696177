import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import imageIcon from "../../../assets/images/vcshIcon.svg";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from 'antd';

const ManageDashboard = ({listarr}) => {


  return (
    <>
      
          <div className="ComapnyCardWrappersMain ">
          {
            listarr.map((val, i) => 
              <div className="CompanyListViewItm" key={i}>
                <div className="companyCardHeader">
                  <div className="brandinfo">
                    <div className="brandLogo">
                      <img src={val.image} />
                    </div>
                    <div className="brandName">
                      <h4>{val.ticker}</h4>
                      <div className="CopanyTitle">
                        <h5>{val.name}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="DataSetings">
               
                  <div className="today dataItm">
                    <span>Open Quantity</span>
                    <h6>{val.OpenQty ? val.OpenQty : 'processing...'}</h6>
                  </div>
                  <div className="marketCap dataItm">
                    <span>Cost</span>
                    <h6>{val.cost ? '$' + val.cost : 'processing...'}</h6>
                  </div>
                  <div className="marketCap dataItm">
                    <span>Market Value</span>
                    <h6>{val.marketvalue ? '$' + val.marketvalue : 'processing...'}</h6>
                  </div>
                  <div className="marketCap dataItm">
                    
                    <span>Market Price</span>
                    <h6>{val.marketprice ? '$' + val.marketprice : 'processing...'}</h6>
                  </div>
                  <div className="marketCap dataItm">
                    <span>Unrealized PL</span>
                    <h6>{val.unrealizedPL ? '$' + val.unrealizedPL : 'processing...'}</h6>
                  </div>
                  <div className="price dataItm">
                    <span>Research</span>
                    <h6>
                      <a href={val.research} target="_blank">LINK</a>
                    </h6>
                  </div>
                  <div className="settingButtons dataItm">
                    <a className="PlusBtn d-none"></a>
                    <a className="viewBtn ml-0"></a>
                  </div>
                  
                </div>
              </div>
            )
          }
          </div>
     
    </>
  );
};
export default withRouter(ManageDashboard);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import configureStore from "./redux/store";
import reportWebVitals from './reportWebVitals';

const { persistor, store } = configureStore();

const reduxRoot = (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>
);

if (process.env.NODE_ENV === 'production') {
  ReactDOM.render(
    reduxRoot,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    // <React.StrictMode>
    <>
      {reduxRoot}
      </>,
    //</React.StrictMode>, 
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

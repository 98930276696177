import { ActionType } from "../model";
import { notifyMe } from "../helper";

// export const ListTransactions = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
// 	try {
// 		let resp = await api.get('get_transactions', data, getState().auth.access_token);
// 		if (resp.code == '200') {
// 			return dispatch({ type: ActionType.LIST_TRANSACTIONS, payload: resp.data });
// 		} else if (resp.code && resp.code != '200') {
// 			notifyMe({ key: 'transaction', method: 'error', message: resp.message, description: resp.data.error_description });
// 		}
// 	} catch (error) {
// 		console.log(error)
// 	}
// };

/**Fetch transaction*/
export const ListTransactions = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_transactions_from_unit', data, getState().auth.access_token);
		if (resp.code == '200') {
			return dispatch({ type: ActionType.LIST_TRANSACTIONS, payload: resp.data });
		} else if (resp.code && resp.code != '200') {
			notifyMe({ key: 'transaction', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (error) {
		console.log(error)
	}
};

export const GetLaterExpense = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_individual_later_expenses', data, getState().auth.access_token);
		if (resp.code == '200') {
			return dispatch({ type: ActionType.GET_LATER_EXPENSE, payload: resp.data });
		} else if (resp.code && resp.code != '200') {
			notifyMe({ key: 'transaction', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (error) {
		console.log(error)
	}
};

export const getexpense = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('get_individual_expenses_initially', data, '',   getState().auth.access_token);
		return dispatch({ type: ActionType.GET_EXPENSE_DETAILS, payload: resp.data });
		
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
	}
};

export const getexpensefilters = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('get_individual_expenses', data, '',   getState().auth.access_token);
		return dispatch({ type: ActionType.GET_EXPENSE_FILTERS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
	}
};

export const getExpensesLatestStatus = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_individual_expenses_latest_status', data, getState().auth.access_token);
		return resp;
		if (resp.code == '200') {
			
		} else if (resp.code && resp.code != '200') {
			notifyMe({ key: 'transaction', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (error) {
		console.log(error)
	}
};

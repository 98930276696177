import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons';
import logo from '../../../../assets/images/LogoDarkNew.svg';
import logoCollaplse from '../../../../assets/images/LogoIcon.svg';
import logoCollapsed from '../../../../assets/images/logo/mini-logo.svg';
import ProfileImgLeft from '../../../../assets/images/ProfileImgLeft.png';
import { useActions, authAction, myProfileAction } from "../../../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const Sidebar = () => {
  const [isShowSetting, setIsShowSetting] = useState<boolean>(false)
  const authActions = useActions(authAction);
  const myProfileauth = useSelector((state: RootState) => state.auth);


  const logOut = async () => {
    window.localStorage.clear();
    await authActions.SignOut()
  }

  // useEffect(() => {
  //   _fetchuserInfo();
  //   // myProfileActions.MyProfile();
  // }, []);

  // const _fetchuserInfo = async () => {
  //   await authActions.GetUserInfoByAccessToken();

  // }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowSetting(isShowSetting)
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [SidebarCollapse, sidebarShowHide] = React.useState(false);


  const PlanSelectClick = (e: any) => {
    if (!SidebarCollapse) sidebarShowHide(true);
    else sidebarShowHide(false);
  }
  //sidebarShowHide(false)

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [messageCount, setMessageCount] = useState(0);

  return (
    <>
      <div className={SidebarCollapse ? "leftSidebar Collapsed" : "leftSidebar"}>
        <nav>
          <ul className="mainMenu">
            <li className="logo"  ><div> {SidebarCollapse ? <img src="/SO_favicon_green.png" alt=" Logo" /> : <img src="/assets/images/logo/SO_Logo_Green.png" alt=" Logo" />}  </div><a href="#" className="menuHideShow" onClick={PlanSelectClick} > {SidebarCollapse ? <span className="menuIconCollapse"></span> : <span className="menuIconCollapse menuRight"></span>} </a> </li>
            <li><NavLink to="/dashboard" activeClassName="active"><span className="iconLetBar icon1"></span> Home</NavLink></li>
            {/* {myProfileauth?.user?.user_type !== 'SO_CUSTOMER' &&
                <> */}
                  <li> <NavLink to="/transactions" activeClassName="active"><span className="iconLetBar icon2"></span> Transactions</NavLink></li>
                  <li> <NavLink to="/contributions" activeClassName="active"><span className="iconLetBar icon3"></span> Contributions</NavLink></li>
                {/* </>
            }  */}
                              <li> <NavLink to="/expenses" activeClassName="active"><span className="iconLetBar icon8"></span> Expenses</NavLink></li>
            <li> <NavLink to="/documents" activeClassName="active"><span className="iconLetBar icon5"></span> Documents</NavLink></li>
            <li> <NavLink to="/investment_choices" className="purpleMenu" activeClassName="active"><span className="iconLetBar icon7"></span> Investments</NavLink></li>

            <li> <NavLink to="/forYou" activeClassName="active"><span className="iconLetBar icon6"></span> For You</NavLink></li>
            {/* <li> <NavLink to="/eligible" activeClassName="active"><span className="iconLetBar icon6"></span> Eligible</NavLink></li> */}
            <li><NavLink to="/messagecenter" activeClassName="active"><span className="iconLetBar icon4">

            </span> Message Center  {messageCount > 0 ?  <span className="countLeftSide">1</span> : ''}
            </NavLink>
            </li>
            <li> <NavLink to="/settings/profile" className="purpleMenu" activeClassName="active"><span className="iconLetBar icon9"></span> Settings</NavLink></li>

          </ul>
        </nav>
        <div className="letSideProfile" ref={wrapperRef} onClick={() => setIsShowSetting(!isShowSetting)}>
          {myProfileauth?.user ? (<div className="profileIcon" style={{ backgroundImage: 'url(' + myProfileauth?.user?.picture + ')' }} ></div>) : (<div className="profileIcon" style={{ backgroundImage: 'url(' + ProfileImgLeft + ')' }} ></div>)}

          <div className="nameIDLeft">
            <div className="leftName">{myProfileauth?.user ? myProfileauth?.user?.name : 'John Doe'}<br /> </div>
          </div>
          <div className="actionRight" >
            <CaretRightOutlined style={{ fontSize: '14px', color: '#FFFFFF' }} />
            {/* <Link to="" className="linkIcon"></Link> */}
          </div>
          {
            isShowSetting &&
            <div className="sidebarMenuDrop">
              <Link to="/settings/profile" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon1" ></span> Profile</Link>
              <Link to="/settings/dependents" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon2" ></span> Dependents</Link>
              <Link to="/settings/banking" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon3" ></span> Banking</Link>
              <Link to="/settings/beneficiaries" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon3" ></span> Beneficiary</Link>
              <Link to="/settings/preferences" onClick={() => setIsShowSetting(!isShowSetting)}> <span className="menuIconLeftSide icon5" ></span> Preferences</Link>
              <Link to="/signIn" className="logOut" onClick={logOut} >
                <span className="menuIconLeftSide icon6"></span> Logout</Link>
            </div >
          }
        </div>
      </div>
    </>
  );
};

export default Sidebar;

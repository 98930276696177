import { ActionType } from "../model";
import { notifyMe } from "../helper";

export const fetchBankInfo = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('fetchBankInfo', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.INDIVIDUAL_BANK_DATA, payload: resp });
	} catch (error) {
		notifyMe({ key: 'bankinfo', method: 'error', message: 'Error', description: error });
		return dispatch({ type: ActionType.INDIVIDUAL_BANK_DATA, payload: [] });

	}
};



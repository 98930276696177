import { Action, SponsorActionType } from "../../../model";
import createReducer from "../createReducer";

export const finchReducer = createReducer({}, {
	[SponsorActionType.SPONSOR_FINCH_ACCESS_TOKEN](state, action) {
		return action.payload;
	},
	[SponsorActionType.SPONSOR_FINCH_COMPANY_DATA](state, action) {
		return {
			...state, 
			comapnydata:action.payload
		};
	},
	[SponsorActionType.SPONSOR_FINCH_DIRECTORY_DATA](state, action) {
		return {
			...state,
			directorydata:action.payload
		};
	},
	[SponsorActionType.SPONSOR_FINCH_PAYMENT_DATA](state, action) {
		return {
			...state,
			paymentdata:action.payload
		};
	}
});

import React from 'react';
import { withRouter } from "react-router-dom";
import { Sidebar, Topbar } from './components';

interface MainProps {
  children: any
}

const Main = (props: any) => {
  const { children, history } = props;

  const title = (history.location.pathname.substring(1) === 'dashboard') ? 'home' : history.location.pathname.substring(1);
  const settingRoutes = ['/settings/profile', '/settings/dependents', '/settings/banking', '/settings/beneficiaries', '/settings/preferences'];
  
  return (
    <>
      <div className="mainContainer">
        <Sidebar />
        <div className="mainContent">
          {
            !settingRoutes.includes(history.location.pathname) &&
            <Topbar  title={title}
            />
          }      
             
          <>
            {children}
          </>
        </div>
      </div>
    </>
  );
};

export default withRouter(Main);

import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Progress } from 'antd';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import LinkAccount from '../views/CreateAccount/LinkAccount';

const Routes = (props) => {
  const { isAuthenticated } = props;
  console.log(isAuthenticated)
  return (
    <Suspense fallback={<Progress strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} showInfo={false} percent={100} />}>
      {
        
        (isAuthenticated)
          ? 
            <Route path="/" name="PrivateRoutes" component={PrivateRoutes}/>
          :
            <Route path="/" name="PublicRoutes" component={PublicRoutes}/>
        }
    </Suspense>
  );
};

export default Routes;
import React from 'react';

const AdvCrs = () => {
    return (
        <div className="disclosure_event">
            <a href="https://www.intellicents.com/_files/ugd/8c4421_740d45be696c4118b92ad7b8d6f83c71.pdf" target="_blank">ADV</a> | <a href="https://reports.adviserinfo.sec.gov/crs/crs_107498.pdf" target="_blank">CRS</a>
            <br/>
            <small>Investment Advisory Services are offered by intellicents investment solutions, inc., an SEC-registered investment adviser.</small>
        </div>
    )
}

export default AdvCrs

import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const contributions = createReducer({}, {
	[ActionType.GET_CONTRIBUTIONS](state: any, action: Action<any>) {
		return action.payload;
	}
});

export const contributionshis = createReducer({}, {	
	[ActionType.GET_CONTRIBUTION_HISTORY](state: any, action: Action<any>) {
		return action.payload;
	},
});


export const contributionsexpense = createReducer({}, {	
	[ActionType.GET_EXPENSE](state: any, action: Action<any>) {
		return action.payload;
	},
});

export const GetContributionExpenseNew = createReducer({}, {	
	[ActionType.GET_EXPENSE_NEW](state: any, action: Action<any>) {
		return action.payload;
	},
});



import { ActionType } from "../model";
import { notifyMe } from "../helper";

export const GetContributions = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_contributions?year='+data, {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_CONTRIBUTIONS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContribution', method: 'error', message: 'Error', description: error });
		return dispatch({ type: ActionType.GET_CONTRIBUTIONS, payload: [] });

	}
};

export const GetContributionHistory = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_contributions_history', data, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_CONTRIBUTION_HISTORY, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionHistory', method: 'error', message: 'Error', description: error });
		return dispatch({ type: ActionType.GET_CONTRIBUTION_HISTORY, payload: [] });

	}
};

export const GetContributionExpense = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_expanse_summary', data, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_EXPENSE, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
		return dispatch({ type: ActionType.GET_EXPENSE, payload: [] });

	}
};

export const GetContributionExpenseNew = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_expanse_summary', data, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_EXPENSE_NEW, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getContributionExpense', method: 'error', message: 'Error', description: error });
		return dispatch({ type: ActionType.GET_EXPENSE_NEW, payload: [] });
	}
};

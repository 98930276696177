import React from "react";
import { Row, Col } from 'antd';
import { withRouter } from "react-router-dom";
import { Formik } from 'formik';
import {
  SubmitButton,
  Input,
  Form,
  FormItem,
} from "formik-antd";
import { isRequired, validDob, validSsn } from '../../validations';
import InputMask from "react-input-mask";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const initialValues = {
  first_name: '',
  last_name: '',
  ssn4: '',
};

const VerifyIdentity = (props: any) => {
  const { handleSubmit } = props;

  const CustomDob = (props: any) => (
    <InputMask {...props}>{(inputProps: any) => <Input {...inputProps} />}</InputMask>
  );

  const verificationForm = (setFieldValue: any) => (
    <Form {...layout} name="verificationForm" className="defaultForm">
      <FormItem label="First Name" name="first_name" validate={isRequired}>
        <Input name="first_name" placeholder="First Name" />
      </FormItem>

      <FormItem label="Last Name" name="last_name" validate={isRequired}>
        <Input name="last_name" placeholder="Last Name" />
      </FormItem>

      <FormItem label="Date of Birth" name="dob" validate={validDob}>
        <Row className="customRowSelect">
          <Col span={8}>
            <CustomDob
              placeholder="mm/dd/yyyy"
              mask="99/99/9999"
              name="dob"
              onChange={(e: any) => {
                const value = e.target.value || "";
                setFieldValue("dob", value);
              }}
            />
          </Col>
        </Row>
      </FormItem>

      <FormItem label="Last 4 digits of SSN" name="ssn4" className="passwordLinkItem" validate={validSsn}>
        <Input.Password name="ssn4" placeholder="Social Security Number" />
      </FormItem>

      <FormItem name="verification" className="submitButton">
        <SubmitButton block>Continue</SubmitButton>
      </FormItem>
    </Form>);

  return (
    <div className="login-inner">

    
    {/* ---------------------------------------------account-locked---------------------------------------------- */}

      <div className="verifyError d-none">
        <div className="errorImg"></div>
        <div className="errorMessageBody">
          <h2 className="errorTitle">Account Temporarily Locked</h2>
          <p className="ErrorDescription mb0">To protect your information, your account is temporarily locked. Please contact support@savingsoak.com</p>
        </div>
      </div>

       {/* ---------------------------------------------account-locked---------------------------------------------- */}
      <div className="logo-text-section">
        <div className="loginHeadings">
          <h3 className="mb-10">Verify your identity</h3>
          <p>For your security, please provide the following information to complete verification. </p>
        </div>
      </div>
      <div className="clearFix"></div>
      <Formik
        key={'verifyForm'}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => verificationForm(setFieldValue)}
      </Formik>
      <div className="register-link">
      </div>
    </div>
  );
};

export default withRouter(VerifyIdentity);

import React, { useState, useEffect } from 'react'
import {
  Table,
  Select,
  Pagination,
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Collapse,
  Input,
  Checkbox,
  Carousel,
  Radio,
  Breadcrumb,
  Steps,
  message,
  Spin,
  InputNumber,
} from "antd";
import ApexCharts from 'apexcharts';
import Moment from 'moment';
import momentTz from 'moment-timezone';
import MoveMoney from '../../../views/Investments/FundTransfer/MoveMoney';
import { useActions, investmentAction, getStatesAction } from "../../../actions";
import GridListTables from '../../../views/ViewComponent/GridListTables';

const { TabPane } = Tabs;
const AllActivity = (props) => {
  const [transArr, setTransArr] = useState([]);
  const [rows, setRows] = useState(10);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [page, setPage] = useState(0);
  const investmentcallAction = useActions(getStatesAction);
  const [dashboardData, setdashboardData] = useState([]);
  const [investmentequity, setInvestmentequity] = useState([]);
  const [typeofchart, settypeofchart] = useState('week');
  const [asofdate, setasofdate] = useState('');
  const [activeTab, setactiveTab] = useState('1');
  const [dwAccountNumber, setDwAccountNumber] = useState();
  const [showstate, setshowstate] = useState('fund');
  const [noDataFound, setNoDataFound] = useState('')
  const [totalPrice, setTotalPrice] = useState('0');
  const [isloading, setIsloading] = useState(false);


  useEffect(() => {
    _fetchDashboardData();
  }, []);
  //2022-02-10T23:59:59.999Z


  const _fetchDashboardData = async () => {
    setIsloading(true)
    let newDate = new Date();
    let tz = momentTz.tz.guess();
    let ToDate = Moment(newDate).format('YYYY-MM-DDTH:MM')

    let data = {
      'from': '2021-12-10T00:00:00.000Z',
      'to': ToDate + ':00.000Z',
      'direction': 'prev'
    }

    let dashboard = await investmentcallAction.getDwTransactions(data);

    if (dashboard.payload.data.transactionsData.length > 0) {
      setInvestmentequity(dashboard.payload.data.transactionsData);
      setIsloading(false)
    } else {
      setIsloading(true)
      setNoDataFound('There are no all activity at this time for your account.')
      setIsloading(false)
    }

    setDwAccountNumber('Investment Account Number: ' + dashboard.payload.dw_account_number)
    callInterval();
  }

  const callInterval = () => {
    window.setInterval(function () {
      _fetchDashboardData();
    }, 300000);
  }

  const columns = [
    {
      title: (filters, sortOrder) => 'Date', dataIndex: 'tranWhen', sorter: { compare: (a, b) => new Date(a.tranWhen) - new Date(b.tranWhen) },
      render: (tranWhen) => <span>{Moment(tranWhen).format('MM/DD/YYYY')}</span>
    },
    {
      title: (filters, sortOrder) => 'Equity Name', dataIndex: 'symbol', sorter: { compare: (a, b) => a.symbol - b.symbol, multiple: 2, },
    },
    {
      title: (filters, sortOrder) => 'Activity Type', dataIndex: 'finTranTypeVal', sorter: { compare: (a, b) => a.finTranTypeVal - b.finTranTypeVal, multiple: 3, },
    },
    // {
    //   title: (filters, sortOrder) => 'Activity Type', dataIndex: 'accountType', sorter: { compare: (a, b) => a.category.localeCompare(b.category), multiple: 5, },
    //   render: (category) => <span>{category}</span>
    // },
    {
      title: (filters, sortOrder) => 'Quantity', dataIndex: 'fillQty', sorter: { compare: (a, b) => a.fillQty - b.fillQty, multiple: 3, },
      render: (total) => <span>{total}</span>
    },
    {
      title: (filters, sortOrder) => 'Price', dataIndex: 'price', sorter: { compare: (a, b) => a.price - b.price, multiple: 2, },
      render: (total) => <span>${total}</span>
    },
    {
      title: (filters, sortOrder) => 'Amount', dataIndex: 'tranAmount', sorter: { compare: (a, b) => a.tranAmount - b.tranAmount, multiple: 2, },
      render: (total) => <span>${total}</span>
    },

  ];

  return (
    <>
      <div className="containerNew ScrollViewMain scrollDashboard moveMoneyWrapper">
        <Card className="TransactionMain">
          <Breadcrumb className="FundTrabsferBreadcrumb" separator=">">
            <Breadcrumb.Item>Transactions</Breadcrumb.Item>
            <Breadcrumb.Item>All Activity</Breadcrumb.Item>
          </Breadcrumb>
          <Row className="row">
            <Col span={24} className="col-md-8">
              <div className="investBannerText pt0">
                <div>
                  <h2 className="investSubHead">
                    All Activity
                  </h2>
                  <p>
                    Trades listed below will be submitted for execution upon market open unless cancelled while pending.
                    Share amounts listed below are an estimate until trade is executed at actual market share shares.

                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isloading ? <div className="spinnerCont"><Spin tip="Loading..." /></div> :
                <Card className="card cardWhite heightAuto transactionTable ">
                  <div className="ComapnyCardWrappersMain ">
                    {
                      noDataFound == '' ?
                        <Table
                          className="paginationDisable"
                          style={{ width: '100%' }}
                          columns={columns}

                          dataSource={investmentequity}
                          pagination={false}
                        />
                        : <p>{noDataFound}</p>
                    }
                  </div>
                </Card>
              }
            </Col>
          </Row>
          <div className='totalDate'>
            <p className="totalAllocate">{totalPrice != '0' ? <>TOTAL :   ${totalPrice}</> : ''}</p>
            <p> {asofdate != '' ? <><strong>* Data</strong> {asofdate}</> : ''}</p>
          </div>

        </Card>
      </div>

    </>
  )
}
export default AllActivity;
import { Action, ActionType } from "../../model";
import createReducer from "./createReducer";

export const beneficiaries = createReducer({}, {
	[ActionType.LIST_BENEFICIARIES](state: any, action: Action<any>) {
		return action.payload;
	},	
});

export const addBeneficiary = createReducer({}, {
	[ActionType.ADD_BENEFICIARY](state: any, action: Action<any>) {
		return action.payload;
	},	
});

// export const editBeneficiary = createReducer({}, {
// 	[ActionType.EDIT_BENEFICIARY](state: any, action: Action<any>) {
// 		return action.payload;
// 	},	
// });

export const delBeneficiary = createReducer({}, {
	[ActionType.DEL_BENEFICIARY](state: any, action: Action<any>) {
		return action.payload;
	},	
});
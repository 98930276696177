import { ActionType } from "../model";
import { notifyMe } from "../helper";



export const MyProfile = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_profile', {}, getState().auth.access_token);
		return dispatch({
			type: ActionType.MY_PROFILE,
			payload: resp.data
		});
	} catch (err) {
		console.error('Profile Error: ', err);
	}
};

export const getstates = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.get('get_states', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.PARTICIPANT_STATES, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};

export const saveProfile = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('update_profile', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.UPDATE_PROFILE, payload: resp });
	} catch (error) {
		notifyMe({ key: 'get_states', method: 'error', message: 'Error', description: error });
	}
};




export const UpdatePassword = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let resp = await api.post('change_password_inprofile', data, '',  getState().auth.access_token);
		return dispatch({
			type: ActionType.UPDATE_PASSWORD,
			payload: resp
		});
	} catch (err) {
		console.error('Update Password Error: ', err);
	}
};

import { SponsorActionType, ActionType } from "../../model";
import { notifyMe } from "../../helper";


export const SignIn = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('auth0_login_sponsor', data);

		if (resp.code === 200) {
			dispatch({ type: SponsorActionType.SPONSOR_SIGNIN, payload: resp.data });
			return true;
		} else if (resp.code === 201) {
			notifyMe({ key: 'signIn', method: 'error', message: 'Error', description: resp.message });
			return false;
		} else if (resp.code === 207) {
			notifyMe({ key: 'signIn', method: 'error', message: resp.message, description: resp.data.error_description });
			return false;
		}
	} catch (error) {
		notifyMe({ key: 'signIn', method: 'error', message: 'Error', description: error });
	}
};

export const ActivateAccount = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('send_activation_email_sponsor', data);
		return dispatch({ type: SponsorActionType.SPONSOR_ACTIVATE_ACCOUNT, payload: resp.message });
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ResetLoginAttempts = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('reset_login_attempts', data);
		if (resp.code === 200) {
			notifyMe({ key: 'resetLoginAttempt', method: 'success', message: 'Success', description: resp.message });
			dispatch({ type: SponsorActionType.SPONSOR_RESET_LOGIN_ATTEMPTS, payload: resp.data });
			return true;
		} else if (resp.code === 404) {
			notifyMe({ key: 'verifyAcc', method: 'error', message: 'Error', description: resp.message });
			return false;
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const GetUserInfoByAccessToken = () => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_userinfo_by_access_token_sponsor', {}, getState().auth.access_token);
		return dispatch({
			type: SponsorActionType.SPONSOR_USER_INFO,
			payload: resp.data
		});
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const SignOut = () => async (dispatch, getState, api) => {
	dispatch({ type: ActionType.SIGNOUT, payload: {} });
	return dispatch({ type: SponsorActionType.SPONSOR_SIGNOUT, payload: {} });
};

export const VerifyAccount = (data) => async (dispatch, getState, api) => {
	try {
		const resp = await api.post('verify_sponsor_by_firstname_lastname', data);
		if (resp.code === 200) {
			notifyMe({ key: 'verifyAcc', method: 'success', message: 'Success', description: resp.message });
			dispatch({ type: SponsorActionType.SPONSOR_VERIFY_ACCOUNT, payload: resp.data });
			return true;
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'verifyAcc', method: 'error', message: 'Error', description: resp.message });
			return false;
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ForgotPassword = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('send_forgotpassword_email_sponsor', data);
		if (resp.code === 200) {
			notifyMe({ key: 'forgotPwd', method: 'success', message: 'Please check your email for reset instructions' });
			return dispatch({ type: SponsorActionType.SPONSOR_FORGOT_PASSWORD, payload: resp.data });
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'forgotPwd', method: 'error', message: 'Error', description: 'Error in sending email' });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const SponsorCheckVerificationToken = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('check_token_status_sponsor', data);
		if (resp.code === 200) {
			dispatch({ type: SponsorActionType.SPONSOR_CHECK_VERIFICATION_TOKEN, payload: resp });
			return {
				isValid: true,
				code: resp.code
			};
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'verifyToken', method: 'error', message: 'Verification Token', description: resp.message });
			return {
				isValid: false,
				code: resp.code
			};
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ChangePassword = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('change_password_sponsor', data);
		if (resp.code === 200) {
			notifyMe({ key: 'changePwd', method: 'success', message: 'Success', description: 'Your password was reset successfully. Please sign in to continue' });
			return dispatch({ type: SponsorActionType.SPONSOR_CHANGE_PASSWORD, payload: resp.data });
		} else if (resp.code && resp.code !== 200){
			notifyMe({ key: 'changePwd', method: 'error', message: 'Error', description: resp.message });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ResendVerificationMail = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('send_invitation_email_again_sponsor', data);
		if (resp.code === 200) {
			notifyMe({ key: 'resendVerifyEmail', method: 'success', message: 'Success', description: resp.message });
			return dispatch({ type: SponsorActionType.SPONSOR_RESEND_VERIFICATION_MAIL, payload: resp });
		}
		else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'resendVerifyEmail', method: 'error', message: 'Error', description: resp.message });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const AuthAutomaticSignIn = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('auth_automatic_signup_login_sponsor', data);
		if (resp.code === 200) {
			return dispatch({ type: SponsorActionType.SPONSOR_SIGNIN, payload: resp.data });
		} else if (resp.code && resp.code !== 200) {
			notifyMe({ key: 'signIn', method: 'error', message: resp.message, description: resp.data.error_description });
		}
	} catch (err) {
		console.error('Auth Error: ', err);
	}
};

export const ValidateAuthToken = (authToken) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('validate_auth0_token', '', authToken);
		return resp;
		// if (resp.code === 200) {
		// 	return dispatch({ type: SponsorActionType.SPONSOR_SIGNIN, payload: resp.data });
		// } else if (resp.code && resp.code !== 200) {
		// 	notifyMe({ key: 'signIn', method: 'error', message: resp.message, description: resp.data.error_description });
		// }
	} catch (err) {
		console.error('Auth Error: ', err);	
	}
}

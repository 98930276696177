import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Select, Spin, Tabs, Menu, Dropdown } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { withRouter, Link } from "react-router-dom";
import { useActions, investmentAction, contrAction, transAction} from "../../../actions";
import moment from 'moment';
import { useSelector } from "react-redux";

// import 'moment-timezone';
import momentTz from 'moment-timezone';
import InvestmentOverview from '../../../components/investment/investmentdashboard/dashboard';
import MoveMoney from "../FundTransfer/FundTabs";
import Transactions from "../FundTransfer/transactions";
import PendingTrade from "../../../components/investment/Orders/pending_orders";
import ExecutedTrade from "../../../components/investment/Orders/executed_orders";
import { useHistory } from "react-router-dom";
import AllActivity from "../../../components/investment/Orders/AllActivity";
const { TabPane } = Tabs;

const InvestmentDashboard = ({ type }) => {

  var currentYear = new Date().getFullYear();
  const investmentcallAction = useActions(investmentAction);
  const contrActions = useActions(contrAction);
  const transActions = useActions(transAction);
  const [dashboardData, setdashboardData] = useState([]);
  const [transactionList, settransactionList] = useState([]);
  const [contributionSummary, setcontributionSummary] = useState([]);
  const [expenseSummaryChart, setexpenseSummaryChart] = useState([]);
  const [investmentequity, setInvestmentequity] = useState([]);
  const [typeofchart, settypeofchart] = useState('week');
  const [asofdate, setasofdate] = useState('');
  const [activeTab, setactiveTab] = useState('1');
  const [dwAccountNumber, setDwAccountNumber] = useState();
  const [showstate, setshowstate] = useState('processed');
  const [equityCategory, setEquaityCategory] = useState([]);
  const [investmentdata, setinvestmentdata] = useState([]);
  const [portfolioname, setportfolioname] = useState('');
  const myProfileauth = useSelector((state) => state.auth);

  const history = useHistory()


  useEffect(() => {
    _fetchDashboardData();

  }, []);

  const _fetchDashboardData = async () => {
    // let dashboard = await investmentcallAction.getinvestmentsdashboard();
    let dashboard = await investmentcallAction.getinvestmentequity();
    setdashboardData(dashboard.payload);
    let investableresp = await investmentcallAction.getinvestmentequityNew();
    setinvestmentdata(investableresp?.payload);
    let tz = momentTz.tz.guess();
    setportfolioname(dashboard.payload.portfolio_name);
    if(dashboard.payload.lastUpdated) {
      let time = moment(dashboard.payload.lastUpdated).format('llll') + ' (' + momentTz().tz(tz).format('z') + ')';

        setasofdate(time)
    }
    if (dashboard.payload.equityPositions.length > 0) {
      setInvestmentequity(getarray(dashboard.payload.equityPositions));
      setEquaityCategory(dashboard.payload.asset_class_morningstar_category)
    }
    if(myProfileauth?.user?.user_type == 'SO_CUSTOMER') {

    } else {
      const transaction = await transActions.ListTransactions(10, 0);
      settransactionList(transaction?.payload);
      let resp = await createTransactionlist(transaction?.payload);
      const contraction = await contrActions.GetContributions(
        currentYear.toString()
      );
      setcontributionSummary(contraction.payload);
      setexpenseSummaryChart(contraction.payload);
    }

    setDwAccountNumber('Investment Account Number: ' + dashboard.payload.dw_account_number)
    callInterval();
  }
  const createTransactionlist = (transactionlist) => {
    let trans_data = [];
    if (transactionlist.length == 0) {
      console.log("empty");
    } else {
      const { transactions } = transactionlist;
      transactions.map((value, index) =>
        trans_data.push({
          key: index,
          date: moment(value?.datetime).format("ll"),
          provider: value?.merchant_name,
          type: value?.type,
          individualName: value?.individual_name,
          amount: "$" + value?.amount,
          status: {
            text: "Paid",
            hasInfo: false,
          },
          exptandData: [
            {
              merchantLocKey: "Merchant Location",
              merchantLocText: value?.merchant_location,
            },
          ],
        })
      );
    }

    return trans_data;
  };


  console.log(investmentequity)

  const callInterval = () => {
    window.setInterval(function () {
      _fetchDashboardData();
    }, 300000);
  }

  const moveObject = (arr, targetKey, targetValue, newIndex) => {
    const target = arr.find(value =>  value[targetKey] === targetValue)
    const newArray = arr.filter(value =>  value[targetKey] != targetValue)
    
    newArray.splice(newIndex, 0, target);
    
    return newArray.filter((val) => val !== undefined);
  }

  const getarray = (arrval) => {
    // console.log('arrval', arrval)
    let arr = [];
    arrval.map((val, i) => {
      arr.push({
        key: i,
        name: val.name ? val.name : '',
        ticker: val.symbol ? val.symbol : '',
        research: val.url ? val.url : '',
        image: val.image ? val.image : '',
        OpenQty: val.openQty ? val.openQty : '',
        cost: val.costBasis ? val.costBasis : '',
        marketvalue: val.marketValue ? val.marketValue : '',
        mktprice: val.mktPrice ? val.mktPrice : '',
        unrealizedPL: val.unrealizedPL ? val.unrealizedPL : '',
        value:val.close ? val.close : '',
        asset_class_morningstar_category: val.asset_class_morningstar_category ? val.asset_class_morningstar_category : '',
      });
    });    
    return moveObject(arr, 'name', 'CASH_RESERVE', arr.length);
  }

  const callback = (key) => {
    setactiveTab(key)
    if (key ==1 || 3 || 4){
      window.history.replaceState(null, "", "/investment_choices");
    }
    if(key == 2){
      window.history.replaceState(null, "", "/all-activity");
    }
  }

  const pendingOrder = () => {
    setshowstate('pending');
    setTimeout(function(){
      window.history.replaceState(null, "", "/pending-orders");
    }, 100)
  }
  const allActivity = () => {
    setshowstate('allActivity');
    setTimeout(function(){
      window.history.replaceState(null, "", "/all-activity")
    }, 100);
  }

  const txtField = React.useRef(null);

  const executedOrder = () => {
    setshowstate('processed')
    window.history.replaceState(null, "", "/executed-orders")
  }

  const allTransaction = () => {
    setshowstate('processed')
  }

  const menu = (
    <Menu>
       <Menu.Item>
        <a onClick={allActivity} href="javascript:void();"  inputRef={txtField} >
          All Activity
        </a>
      </Menu.Item>
      <Menu.Item  >
        <a onClick={executedOrder} href="javascript:void();">
          Executed Orders
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={pendingOrder} href="javascript:void();">
          Pending Orders
        </a>
      </Menu.Item>

    </Menu >
  );

  const changeexecutedOrder = () => {
    setactiveTab('2');
    setshowstate('processed')
    window.history.replaceState(null, "", "/executed-orders")

  }

  const changeAllActivity = () => {
    setactiveTab('2');
    setshowstate('allActivity');
    window.history.replaceState(null, "", "/all-activity")

  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        activeKey={activeTab}
        className="investmentTabs"
      >
        <TabPane abPane tab="Overview" key="1" >
          <InvestmentOverview
            asofdate={asofdate}
            dashboardData={dashboardData}
            typeofchart={typeofchart}
            settypeofchart={settypeofchart}
            investmentequity={investmentequity}
            dwAccountNumber={dwAccountNumber}
            equityCategory={equityCategory}
            Investmentdata={investmentdata}
            contributionSummary={contributionSummary}
            portfolioname={portfolioname}
          />
        </TabPane>
        <TabPane abPane tab={
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Transactions
            </a>
          </Dropdown>} key="2"  >
          <>
            {/* {menuShow ?

        : ''} */}
            {showstate == 'processed'
              ? <ExecutedTrade />
              : showstate == 'pending'
                ? <PendingTrade />
                : showstate == 'processed'
                  ? <ExecutedTrade /> : showstate == 'allActivity' ? <AllActivity /> : ''
            }
          </>
        </TabPane>
        {/* <TabPane tab="Move Money" key="3">
          <MoveMoney
            asofdate={asofdate}
            dashboardData={dashboardData}
            executedOrder={changeAllActivity}
          />
        </TabPane> */}
        {/* <TabPane tab="Documents" key="4">
        </TabPane>
        <TabPane tab="Account Settings" key="5">
        </TabPane> */}

      </Tabs>

    </>
  );
};

export default withRouter(InvestmentDashboard);
